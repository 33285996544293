import GtmEvent from './GtmEvent'
import { DEFAULT_PRODUCT } from '@constants/ProductSettings'
import VueGtm from 'vue-gtm'
import router from '../../router/router'
import store from '../../store/store'
import { get } from 'lodash'


export default function install(Vue) {
    function _getExternalId(store, toRoute) {
        return store.getters['processStep/externalId'] || get(toRoute, 'query.externalId') || null
    }

    function _getProductName(store, toRoute) {
        return store.getters['products/getProductData'].productName
            || get(toRoute, 'query.productName')
            || DEFAULT_PRODUCT.productName
    }

    function _sendGtmEvent(event) {
        Vue.gtm.trackEvent(event.filterEmptyValues())
    }

    if (window.config.GTM_CLIENT_ID) {
        Vue.use(VueGtm, {
            id: window.config.GTM_CLIENT_ID,
            enabled: true,
            debug: window.config.ENVIRONMENT !== 'production',
        })

        router.afterEach(toRoute => {
            _sendGtmEvent(new GtmEvent({
                bbeventcategory: _getProductName(store, toRoute),
                bbeventlabel: `Loan application ${toRoute.name} Pageview`,
                bbapplicationid: _getExternalId(store, toRoute),
            }))

            _sendGtmEvent(new GtmEvent({
                event: 'pageview',
                bbeventcategory: _getProductName(store, toRoute),
                bbeventlabel: `Loan application ${toRoute.name} Pageview`,
                bbapplicationid: _getExternalId(store, toRoute),
            }))
        })
    }

    Vue.prototype.$sendGtmClickEvent = function (eventLabel) {
        if (window.config.GTM_CLIENT_ID) {
            _sendGtmEvent(new GtmEvent({
                event: 'click',
                bbeventaction: 'ButtonClick',
                bbeventcategory: _getProductName(store),
                bbeventlabel: eventLabel,
                bbapplicationid: _getExternalId(store),
            }))
        }
    }
}

<template>
    <div class="contract-page">
        <loader v-if="contractDetailsLoading || contractSigned"/>
        <template v-else>
            <bb-contract-page class="w-100"
                              :conditions-paragraph="$t('contractMainConditions.description')"
                              :condition-lists="conditionLists"
                              :download-paragraphs="[$t('contractDetails.download.description')]"
                              :loan-amount="loanBoxes.loanAmount"
                              :monthly-payment="loanBoxes.monthlyPayment"
                              :period="loanBoxes.period"
                              :sign-button="signButton"
                              @download="onDownload"
                              @sign="onSign">
                <section slot="steps" class="bg-blue">
                    <offer-steps :active-step="4"/>
                </section>
                <template slot="extraContent">
                    <p class="smallest text-center m-t-20">
                        {{ $t('contractDetails.incorrectData.disclaimer.text') }}
                        <bb-button color="blue"
                                   display="link"
                                   :label="$t('contractDetails.incorrectData.disclaimer.button')"
                                   @click="showIncorrectDataModal()"/>
                    </p>
                    <payment-schedule/>
                    <p class="smallest text-center m-t-20" v-if="!downloadButtonClicked">
                        {{ $t('contractDetails.sign.disclaimer') }}
                    </p>
                </template>
            </bb-contract-page>
            <document-check-modal v-if="documentCheckModalVisible"
                                  @cancel="documentCheckModalVisible = false"
                                  @success="onDocumentCheckSuccess"/>
            <evrotrust-signing-modal v-if="signingModalVisible"
                                     @signingCancelled="onSigningCancelled"
                                     @signingCompleted="onSigningCompleted"/>
            <incorrect-data-modal v-if="incorrectDataModalVisible"
                                  @close="incorrectDataModalVisible = false"/>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import { BbButton, BbContractPage } from '@bigbank/interface-components'
import Loader from '../../views/components/Loader'
import StringHelper from '../../util/StringHelper'
import OfferSteps from '../../views/components/OfferSteps'
import PaymentSchedule from '../../views/components/PaymentSchedule'
import EvrotrustSigningModal from '../components/EvrotrustSigningModal'
import IncorrectDataModal from '../components/IncorrectDataModal'
import DocumentCheckModal from "@components/DocumentCheckModal"
import { applicationNumberIsGreaterThan } from '@util/ApplicationUtil'

export default {
    name: 'view-and-sign-contract-page',

    data() {
        return {
            signingModalVisible: false,
            contractSigned: false,
            incorrectDataModalVisible: false,
            downloadButtonClicked: false,
            contractDownloaded: false,
            documentCheckModalVisible: false,
        }
    },

    computed: {
        ...mapGetters('processStep', ['application']),
        ...mapGetters('contract', ['contractDetails', 'contractDetailsLoading']),
        ...mapGetters('offer', ['offerDetails']),

        conditionLists() {
            return [{
                heading: this.$t('contractMainConditions.title'),
                list: [
                    {
                        title: this.$t('contractMainConditions.details.contractNumber'),
                        content: get(this.contractDetails, 'agreementData.contractNumber'),
                    },
                    {
                        title: this.$t('contractMainConditions.details.iban'),
                        content: StringHelper.formatIBAN(get(this.contractDetails, 'paymentData.beneficiaryIban')),
                    },
                    {
                        title: this.$t('contractMainConditions.details.repaymentDay'),
                        content: this.repaymentDay,
                    },
                    {
                        title: this.$t('contractMainConditions.details.interest'),
                        content: this.$formatPercentage(get(this.contractDetails, 'paymentData.interestRate')),
                    },
                    {
                        title: this.$t('contractMainConditions.details.totalAmount'),
                        content: this.$formatCurrency(get(this.contractDetails, 'paymentSchedule.total.total')),
                    },
                    {
                        title: this.$t('contractMainConditions.details.monthlyFee'),
                        content: this.$formatCurrency(get(this.contractDetails, 'agreementData.administrationFee')),
                    },
                    {
                        title: this.$t('contractMainConditions.details.contractFee'),
                        content: this.$formatCurrency(get(this.contractDetails, 'agreementData.conclusionFee')),
                    },
                    {
                        title: this.$t('contractMainConditions.details.aprc'),
                        content: this.$formatPercentage(get(this.offerDetails, 'aprc')),
                    },
                    {
                        title: this.$t('contractMainConditions.details.totalTransferredAmount'),
                        content: this.$formatCurrency(get(this.offerDetails, 'totalAmountTransferredToAccount')),
                    },
                    {
                        title: this.$t('contractDetails.download.title'),
                        button: {
                            label: this.$t('contractDetails.download.button'),
                            clickEventName: 'download',
                        },
                    },
                ],
            }]
        },

        loanBoxes() {
            return {
                loanAmount: {
                    title: this.$t('contractDetails.loanAmount'),
                    content: this.$formatCurrency(this.offerDetails.offeredAmount),
                },
                monthlyPayment: {
                    title: this.$t('contractDetails.monthlyPayment'),
                    content: this.$formatCurrency(this.offerDetails.offeredMonthlyPaymentFull),
                },
                period: {
                    title: this.$t('contractDetails.loanPeriod'),
                    content: this.offerDetails.offeredPeriod,
                },
            }
        },

        repaymentDay() {
            const day = get(this.contractDetails, 'agreementData.paymentDay')

            return day ? `${this.$t('contractMainConditions.details.nthDayOfMonthPrefix')} ${day}${this.$t('contractMainConditions.details.nthDayOfMonth')}` : ''
        },

        signButton() {
            return {
                label: this.contractDownloaded
                    ? this.$t('contractDetails.sign.button')
                    : this.$t('contractDetails.download.title'),
                color: this.downloadButtonClicked ? 'mint' : 'blue',
                loading: this.downloadButtonClicked && !this.contractDownloaded,
                disabled: this.downloadButtonClicked && !this.contractDownloaded,
            }
        },
    },

    created() {
        this.getUnsignedContractDetails()
    },

    methods: {
        ...mapActions('contract', ['getUnsignedContractDetails', 'downloadUnsignedContract']),
        ...mapActions('processStep', ['localUpdateProcessStep']),

        onSign() {
            if (this.contractDownloaded) {
                if (applicationNumberIsGreaterThan(
                    this.application.applicationNumber,
                    window.config.SIGNING_DOCUMENT_CHECK_MINIMUM_APPLICATION_NUMBER)
                ) {
                    this.documentCheckModalVisible = true
                } else {
                    this.signingModalVisible = true
                }
                this.$sendGtmClickEvent('Signing button clicked')
            } else {
                this.onDownload()
            }
        },

        onDocumentCheckSuccess() {
            this.documentCheckModalVisible = false
            this.signingModalVisible = true
        },

        onSigningCancelled() {
            this.signingModalVisible = false
        },

        onSigningCompleted() {
            this.contractSigned = true
            this.signingModalVisible = false
            this.localUpdateProcessStep()
        },

        showIncorrectDataModal() {
            this.incorrectDataModalVisible = true
            this.$sendGtmClickEvent('Incorrect data button clicked')
        },

        onDownload() {
            this.downloadButtonClicked = true

            return this.downloadUnsignedContract().then(() => {
                this.contractDownloaded = true
            })
        },
    },

    components: {
        DocumentCheckModal,
        IncorrectDataModal,
        EvrotrustSigningModal,
        BbContractPage,
        BbButton,
        Loader,
        OfferSteps,
        PaymentSchedule,
    },
}
</script>

<style scoped lang="scss">
.contract-page {
  background-color: white;
  display: flex;
  flex-grow: 1;
  z-index: 1;
}
</style>

import PageNames from '@constants/PageNames'
import ErrorPage from '@pages/ErrorPage'
import DecisionUndecided from '@pages/decision/DecisionUndecided'

export default [
    {
        path: '/error',
        name: PageNames.ERROR_500_PAGE,
        component: ErrorPage,
        props: { errorType: 'error500' },
        meta: { disableSessionExpiration: true },
    },
    {
        path: '/maintenance',
        name: PageNames.MAINTENANCE_PAGE,
        component: ErrorPage,
        props: { errorType: 'maintenance' },
        meta: { disableSessionExpiration: true },
    },
    {
        path: '/processing',
        name: PageNames.VIEW_APPLICATION_PROCESSING_PAGE,
        component: DecisionUndecided,
    },
    {
        path: '/processing-approved-refinancing',
        name: PageNames.VIEW_APPLICATION_PROCESSING_PAGE_APPROVED_REFINANCING,
        component: DecisionUndecided,
    },
    {
        path: '/no-task',
        name: PageNames.NO_TASK_PAGE,
        component: DecisionUndecided,
    },
    {
        path: '/no-process',
        name: PageNames.NO_PROCESS_PAGE,
        component: ErrorPage,
        props: { errorType: 'no-account' },
        meta: { disableSessionExpiration: true },
    },
    {
        path: '/unauthorized',
        name: PageNames.UNAUTHORIZED_PAGE,
        component: ErrorPage,
        props: { errorType: 'no-account' },
        meta: { disableSessionExpiration: true },
    },
    {
        path: '/session-expired',
        name: PageNames.SESSION_EXPIRED_PAGE,
        component: ErrorPage,
        props: { errorType: 'session-expired' },
        meta: { disableSessionExpiration: true },
    },
    {
        path: '*',
        name: PageNames.ERROR_404_PAGE,
        component: ErrorPage,
        props: { errorType: 'error404' },
    },
]

import {
  LOAN_PURPOSE_NAMES,
  LOAN_PURPOSE_OPTIONS,
  getOptionsByValue,
} from './Options'

export const PRODUCT_NAMES = {
    SMALL_LOAN: 'SMALL_LOAN',
    CAR_LOAN: 'CAR_LOAN',
    RENOVATION_LOAN: 'RENOVATION_LOAN',
    REFINANCING_LOAN: 'REFINANCING_LOAN',
}

const SMALL_LOAN_SETTINGS = {
    name: 'small-loan',
    productName: PRODUCT_NAMES.SMALL_LOAN,
    loanPurposes: getOptionsByValue(LOAN_PURPOSE_OPTIONS, [
        LOAN_PURPOSE_NAMES.PURCHASING_FURNISHINGS,
        LOAN_PURPOSE_NAMES.PURCHASING_OR_BUILDING_HOME,
        LOAN_PURPOSE_NAMES.PURCHASING_OTHER_REAL_ESTATE,
        LOAN_PURPOSE_NAMES.STARTING_COMMERCIAL_ACTIVITY,
        LOAN_PURPOSE_NAMES.EXPANDING_COMMERCIAL_ACTIVITY,
        LOAN_PURPOSE_NAMES.ACQUIRING_EDUCATION,
        LOAN_PURPOSE_NAMES.TRAVELING,
        LOAN_PURPOSE_NAMES.MEDICAL_SERVICES,
        LOAN_PURPOSE_NAMES.HOBBY,
        LOAN_PURPOSE_NAMES.WEDDING,
        LOAN_PURPOSE_NAMES.DAILY_SETTLEMENTS,
    ]),
}

const CAR_LOAN_SETTINGS = {
    name: 'car-loan',
    productName: PRODUCT_NAMES.CAR_LOAN,
    loanPurposes: getOptionsByValue(LOAN_PURPOSE_OPTIONS, [LOAN_PURPOSE_NAMES.PURCHASING_MOTOR_VEHICLES]),
}

const RENOVATION_LOAN_SETTINGS = {
    name: 'renovation-loan',
    productName: PRODUCT_NAMES.RENOVATION_LOAN,
    loanPurposes: getOptionsByValue(LOAN_PURPOSE_OPTIONS, [LOAN_PURPOSE_NAMES.RENOVATING_HOME]),
}

const REFINANCING_LOAN_SETTINGS = {
    name: 'refinancing-loan',
    productName: PRODUCT_NAMES.REFINANCING_LOAN,
    loanPurposes: getOptionsByValue(LOAN_PURPOSE_OPTIONS, [LOAN_PURPOSE_NAMES.COMBINING_LOANS]),
}

export const DEFAULT_PRODUCT = SMALL_LOAN_SETTINGS

export const AVAILABLE_PRODUCTS = {
    [PRODUCT_NAMES.SMALL_LOAN]: SMALL_LOAN_SETTINGS,
    [PRODUCT_NAMES.CAR_LOAN]: CAR_LOAN_SETTINGS,
    [PRODUCT_NAMES.RENOVATION_LOAN]: RENOVATION_LOAN_SETTINGS,
    [PRODUCT_NAMES.REFINANCING_LOAN]: REFINANCING_LOAN_SETTINGS,
}

export const getProductName = name => {
    return Object.keys(AVAILABLE_PRODUCTS).find(productName => {
        return [AVAILABLE_PRODUCTS[productName].productName, AVAILABLE_PRODUCTS[productName].name].includes(name)
    })
}

export const getLoanPurposes = productName => AVAILABLE_PRODUCTS[productName].loanPurposes

<template>
    <div>
        <bb-detailed-radio
            name="text"
            :options="offerOptions"
            value="preliminaryOffer"
            @change="optionSelected"
        />
    </div>
</template>

<script>
import { BbDetailedRadio } from '@bigbank/interface-components'
import { mapGetters } from 'vuex'
import MathHelper from '../../../util/MathHelper'

export default {
    name: 'OfferOptions',

    components: {
        BbDetailedRadio,
    },

    created() {
        this.optionSelected('preliminaryOffer')
    },

    computed: {
        ...mapGetters('offer', ['offerDetails']),

        offers() {
            return {
                preliminaryOffer: {
                    description: this.$t('offerPage.option.requestedLoanDescription'),
                    amount: this.offerDetails.offeredAmount,
                    period: this.offerDetails.offeredPeriod,
                    interestRate: this.offerDetails.interestRate,
                    monthlyRepayment: this.offerDetails.offeredMonthlyPaymentFull,
                    special: false,
                },
            }
        },

        offerOptions() {
            return [this.createOption('preliminaryOffer')];
        },
    },

    methods: {
        createOption(optionId) {
            const option = this.offers[optionId];
            const repaymentAmount = option.monthlyRepayment
                ? option.monthlyRepayment
                : MathHelper.getMonthlyPayment(
                    option.amount,
                    option.period,
                    option.interestRate,
                    0,
                );

            return {
                value: optionId,
                description: option.description,
                leftColumn: {
                    label: this.$formatCurrency(option.amount),
                    sublabel: this.$t('offerPage.period', { value: option.period }),
                    sublabelSmall: this.$t('offerPage.interestRate', { value: this.$formatPercentage(option.interestRate) }),
                },
                rightColumn: {
                    label: this.$t('offerPage.monthlyRepayment'),
                    amount: this.$formatCurrency(repaymentAmount),
                },
                special: {
                    enabled: option.special,
                    label: this.$t('offerPage.specialLabel'),
                },
            }
        },

        optionSelected(optionId) {
            const selectedOffer = this.offers[optionId]

            this.$emit('input', {
                loanAmount: selectedOffer.amount,
                loanPeriod: selectedOffer.period,
            })
        },
    },
}
</script>

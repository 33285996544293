import { mapState } from 'vuex'
import { DEFAULT_WEBSITE_LANGUAGE } from '@constants/Global'

export default {
    computed: {
        ...mapState('language', ['languageCode']),

        globalWebUrls() {
            return {
                bg: {
                    contact: 'https://www.bigbank.bg/chesto-zadavani-vaprosi',
                    cookiePolicy: 'https://www.bigbank.bg/pravni-dokumenti?main=47&sec&doc=2002',
                    termsAndConditions: 'https://www.bigbank.bg/pravni-dokumenti?main=36&sec&doc=2347',
                    priceList: 'https://www.bigbank.bg/pravni-dokumenti?main=48&sec&doc=1892',
                    homepage: 'https://www.bigbank.bg',
                    selfService: this.selfServiceUrl,
                    instagram: 'https://www.instagram.com/lifeatbigbank',
                    facebook: 'https://facebook.com/bigbank.bulgaria',
                },
            }
        },

        selfServiceUrl() {
            return window.config && window.config.SELF_SERVICE_URL
        },

        getLinks() {
            return this.globalWebUrls[this.getLangCode(this.languageCode.toLowerCase())]
        },
    },

    methods: {
        getLangCode(lang) {
            return this.globalWebUrls[lang] ? lang : DEFAULT_WEBSITE_LANGUAGE
        },
    },
}

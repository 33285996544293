'use strict'

import { UNITS } from '@constants/Global'

const FEE_TYPE = {
    PERCENT: 'PERCENT',
    FIXED: 'FIXED',
}

export default class Fee {

    constructor(percentFee, fixedFee) {
        if (percentFee) {
            this.type = FEE_TYPE.PERCENT
            this.value = percentFee
        } else if (fixedFee) {
            this.type = FEE_TYPE.FIXED
            this.value = fixedFee
        }
    }

    getValue() {
        return this.value
    }

    getPercent() {
        if (this.isPercent()) {
            return this.value / 100
        }

        return 0
    }

    getFixed() {
        if (this.isFixed()) {
            return this.value
        }

        return 0
    }

    isPercent() {
        return this.type === FEE_TYPE.PERCENT
    }

    isFixed() {
        return this.type === FEE_TYPE.FIXED
    }

    getDisplayValue() {
        if (this.isPercent()) {
            return `${this.value} ${UNITS.percentage}`
        } else if (this.isFixed()) {
            return `${this.value} ${UNITS.currencySymbol}`
        } else {
            return ''
        }
    }
}

import merge from 'lodash.merge'
import { ui } from '@bigbank/web-loco'
import { defaultUiState, uiStates } from './state'

export default {
    [ui.types.UI_UPDATED]: (state, { routeName }) => {
        const ui = uiStates[routeName] || defaultUiState()

        // create deterministic function here instead of merging
        // eslint-disable-next-line no-param-reassign
        state = merge(state, ui);
    },
}

import AdditionalDataPage from '@pages/AdditionalDataPage'
import PageNames from '../../constants/PageNames'

export default [
    {
        path: '/submit-additional-data',
        name: PageNames.SUBMIT_ADDITIONAL_DATA_PAGE,
        component: AdditionalDataPage,
    },
]

import { DEFAULT_LANGUAGE } from '@constants/Global'
import StringHelper from '../../../util/StringHelper'
import i18n from '../../../locales/i18n'

export const SET_LANGUAGE = 'SET_LANGUAGE'

const MUTATE_LANGUAGE = 'MUTATE_LANGUAGE'

const state = {
    languageCode: DEFAULT_LANGUAGE,
}

const actions = {
    [SET_LANGUAGE]({ commit, state }, lang) {
        const newLang = StringHelper.formatLanguage(lang)

        if (newLang && state.languageCode !== newLang) {
            commit(MUTATE_LANGUAGE, newLang)
        }
    },
}

const mutations = {
    [MUTATE_LANGUAGE](state, lang) {
        i18n.locale = lang
        state.languageCode = lang
    },
}

const getters = {
    availableLanguages: (state, getters) => {
        const languages = ['bg']

        if (window.config.ENVIRONMENT === 'development' || window.config.ENVIRONMENT === 'test') {
            languages.push('en')
        }

        return languages.map(lang => {
            return {
                label: lang.toUpperCase(),
                active: lang.toUpperCase() === getters.languageCode.toUpperCase(),
            }
        })
    },

    languageCode: state => state.languageCode,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

import PageNames from '@constants/PageNames'
import ViewAndSignContractPage from '@pages/ViewAndSignContractPage'
import ViewSigningCompletedPage from '@pages/ViewSigningCompletedPage'

export default [
    {
        path: '/contract',
        name: PageNames.VIEW_AND_SIGN_CONTRACT_PAGE,
        component: ViewAndSignContractPage,
    },
    {
        path: '/contract/signed',
        name: PageNames.VIEW_SIGNING_COMPLETED_PAGE,
        component: ViewSigningCompletedPage,
    },
]

<template>
    <bb-radio id="fieldResidenceType"
              name="residenceType"
              :label="$t('additionalInformationPage.fields.residenceType.title')"
              :options="options"
              v-model="currentValue"
              v-validate="'requiredSelection|required'"
              @change="$emit('input', $event)"/>
</template>

<script>
    import { BbRadio } from '@bigbank/interface-components'
    import TranslateOptionsMixin from '../../../mixins/TranslateOptionsMixin'
    import { RESIDENCE_TYPE_OPTIONS } from '@constants/Options'

    export default {
        name: 'residence-type-field',

        inject: ['$validator'],

        components: { BbRadio },

        props: {
            value: {
                type: [Number, String],
                default: '',
            },
        },

        data() {
            return {
                currentValue: this.value,
            }
        },

        mixins: [TranslateOptionsMixin],

        computed: {
            options() {
                return this.translateOptions(RESIDENCE_TYPE_OPTIONS, 'additionalInformationPage.fields.residenceType.options')
            },
        },
    }
</script>

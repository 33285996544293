<template>
    <bb-radio grouped
              id="fieldDependents"
              name="dependents"
              :label="$t('additionalInformationPage.fields.dependents.title')"
              :options="options"
              v-model="currentValue"
              v-validate="'requiredSelection|required'"
              @change="$emit('input', $event)"/>
</template>

<script>
    import { BbRadio } from '@bigbank/interface-components'
    import TranslateOptionsMixin from '../../../mixins/TranslateOptionsMixin'

    export default {
        name: 'dependents-field',

        inject: ['$validator'],

        components: { BbRadio },

        props: {
            value: {
                type: [Number, String],
                default: '',
            },
        },

        data() {
            return {
                currentValue: this.value,
            }
        },

        mixins: [TranslateOptionsMixin],

        computed: {
            options() {
                return Array.apply(null, { length: 6 }).map((n, i) => (
                    i !== 5
                        ? { value: `${i}`, text: `${i}` }
                        : { value: '5_OR_MORE', text: this.$t('additionalInformationPage.fields.dependents.more') }
                ))
            },
        },
    }
</script>

<template>
    <bb-select v-if="visible"
               noempty
               id="fieldLoanPurpose"
               v-validate="'requiredSelection|required'"
               name="loanPurpose"
               :label="$t('additionalInformationPage.fields.loanPurpose.title')"
               :options="options"
               :value="loanPurpose"
               @change="emitData"/>
</template>

<script>
    import { BbSelect } from '@bigbank/interface-components'
    import TranslateOptionsMixin from '../../../mixins/TranslateOptionsMixin'
    import { getLoanPurposes } from '@constants/ProductSettings'

    export default {
        name: 'loan-purpose-field',

        inject: ['$validator'],

        components: { BbSelect },

        mixins: [TranslateOptionsMixin],

        props: {
            loanPurpose: {
                type: String,
                required: false,
            },
            productName: {
                type: String,
                required: true,
            },
        },

        computed: {
            visible() {
                return this.selectedProductLoanPurposes.length > 1
            },

            options() {
                return this.translateOptions(this.selectedProductLoanPurposes, 'additionalInformationPage.fields.loanPurpose.options')
            },

            selectedProductLoanPurposes() {
                return getLoanPurposes(this.productName)
            },
        },

        created() {
            if (!this.visible) {
                this.emitData(this.selectedProductLoanPurposes[0].value)
            }
        },

        methods: {
            emitData(value) {
                this.$emit('input', value)
            },
        },
    }
</script>

import merge from 'lodash.merge'
import { processStep } from '@bigbank/web-loco'
import actions from './actions'
import router from '../../../router/router'

// state has to include all nested keys required for the application processStep
// for Vue getters to be reactive
// Some state comes from common
const state = {
    namespaced: true,

    application: {
        applicationNumber: null,
        applicant: {
            bankAccountNumber: '',
            firstName: null,
        },
        offer: {
            administrationFee: null,
            aprc: null,
            conclusionFee: null,
            conclusionFeePercent: null,
            currency: null,
            customerMaxAmount: null,
            customerMaxMonthlyPayment: null,
            customerMaxMonthlyPaymentFull: null,
            customerMaxPeriod: null,
            customerMinAmount: null,
            customerMinPeriod: null,
            decision: null,
            decisionTime: null,
            interestRate: null,
            manualRules: {},
            offeredAmount: null,
            offeredAmountMinPeriod: null,
            offeredMonthlyPayment: null,
            offeredMonthlyPaymentFull: null,
            offeredPeriod: null,
            offeredProductName: null,
            offeredProductType: null,
            provenirProcessCode: null,
            upsale: null,
            upsaleMaxLoanAmount: null,
        },
        displayedRejectionReason: null,
        creditConditions: {
            loanAmount: null,
            loanPeriod: null,
            productName: null,
        }
    },
}

const getters = {
    externalId: state => {
        return state.application.externalId
    },

    applicantId: state => {
        return state.application.applicantId
    },

    offer: state => {
        return state.application.offer
    },

    creditConditions: state => {
        return state.application.creditConditions
    },

    application: state => {
        return state.application
    },

    negativePage: (state) => {
        return {
            applicant: { firstName: state.application.applicant.firstName },
            channel: state.application.channel,
            rejectionReason: state.application.displayedRejectionReason || 'other',
        }
    },

    applicantFirstName: (state) => state.application.applicant.firstName || '',
}

const mutations = {
    [processStep.types.PROCESS_STEP_UPDATED]: (state, payload) => {
        // eslint-disable-next-line no-param-reassign
        state = merge(state, payload)
        // track time of last received API request
        // This used as key to force vue component re-render
        // https://michaelnthiessen.com/force-re-render
        state.lastUpdated = Date.now()
    },
}

// Allows setting mock client
export const createProcessStepModule = (client) => {
    const processModule = processStep.moduleBuilder(client, router, {})

    return {
        ...processModule,
        state: merge(processModule.state, state),
        getters: {
            ...processModule.getters,
            ...getters,
        },
        mutations: {
            ...processModule.mutations,
            ...mutations,
        },
        actions: {
            ...processModule.actions,
            ...actions,
        },
    }
}

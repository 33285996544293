import MathHelper from '../../../util/MathHelper'
import get from 'lodash.get'

export default {
    offerDetails: (state, getters, rootState) => {
        const offerDetails = get(rootState, 'processStep.application.offer')

        if (!offerDetails) {
            return null
        }

        const totalRepaymentAmount = MathHelper.getTotalRepaymentAmount(offerDetails)
        const totalAmountTransferredToAccount = MathHelper.getTotalAmountTransferredToAccount(offerDetails)

        return {
            ...offerDetails,
            totalRepaymentAmount,
            totalAmountTransferredToAccount,
        }
    },

    totalAmountTransferred(state, getters) {
        return getters.offerDetails.totalAmountTransferredToAccount
    },
}

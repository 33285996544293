export const SET_CALCULATOR_IS_OPEN = 'SET_CALCULATOR_IS_OPEN'
export const MUTATE_CALCULATOR_IS_OPEN = 'MUTATE_CALCULATOR_IS_OPEN'


const state = {
    isOpen: false,
    details: {
        amount: 1000,
        amountStep: 500,
        period: 60,
        periodStep: 1,
        interestRateStep: 1.0,
    },
};

const getters = {
    isOpen: (state) => state.isOpen,
};

const actions = {
    [SET_CALCULATOR_IS_OPEN] ({ commit }, isOpen) {
        commit(MUTATE_CALCULATOR_IS_OPEN, isOpen)
    },
};

const mutations = {
    [MUTATE_CALCULATOR_IS_OPEN] (state, isOpen) {
        state.isOpen = isOpen
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

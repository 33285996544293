<template>
    <div>
        <bb-list-group>
            <bb-list-item condensed :title="$t('offerDetailsListItems.interestRate')">
                {{ $formatPercentage(offerDetails.interestRate) }}
            </bb-list-item>
            <bb-list-item condensed :title="$t('offerDetailsListItems.oneTimeCommission')">
                {{ $formatCurrency(offerDetails.conclusionFee) }}
            </bb-list-item>
            <bb-list-item condensed :title="$t('offerDetailsListItems.administrationFee')">
                {{ $formatCurrency(offerDetails.administrationFee) }}
            </bb-list-item>
            <bb-list-item condensed :title="$t('offerDetailsListItems.annualPercentageRate')">
                {{ $formatPercentage(offerDetails.aprc) }}
            </bb-list-item>
            <bb-list-item condensed :title="$t('offerDetailsListItems.totalRepaymentAmount')">
                {{ $formatCurrency(offerDetails.totalRepaymentAmount) }}
            </bb-list-item>
            <bb-list-item condensed :title="$t('offerDetailsListItems.totalAmountTransferredToYourAccount')">
                {{ $formatCurrency(totalAmountTransferred) }}
            </bb-list-item>
        </bb-list-group>

        <div class="f-smallest text-center m-t-10 m-b-5 p-x-30">
            <p class="color-gray">{{ $t('offerPage.disclaimer') }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BbListGroup, BbListItem } from '@bigbank/interface-components'

export default {
    name: 'OfferDetailsListItems',

    components: {
        BbListGroup,
        BbListItem,
    },

    computed: {
        ...mapGetters('offer', ['offerDetails', 'totalAmountTransferred']),
    },

}
</script>

<template>
    <bb-modal :visible="true"
              class="incorrect-data-modal m-t-50"
              size="x2"
              @close="close()">
        <p>{{$t('contractDetails.incorrectData.modal.text')}}</p>

        <div class="m-t-20">
            {{ $t('contractDetails.incorrectData.modal.giveUsACall') }}
            <bb-link secondary color="mint" :to="`tel:${CONTACT_PHONE}`">
                {{ CONTACT_PHONE }}
            </bb-link>
        </div>
        <div>
            {{ $t('contractDetails.incorrectData.modal.writeUs') }}
            <bb-link secondary color="mint" :to="`mailto:${CONTACT_EMAIL}`">
                {{ CONTACT_EMAIL }}
            </bb-link>
        </div>
    </bb-modal>
</template>

<script>
    import { BbModal, BbLink } from '@bigbank/interface-components'
    import { CONTACT_EMAIL, CONTACT_PHONE } from '@constants/Global'


    export default {
        name: 'IncorrectDataModal',

        data() {
            return {
                CONTACT_EMAIL,
                CONTACT_PHONE,
            }
        },

        methods: {
            close() {
                this.$emit('close')
            },
        },

        components: {
            BbModal,
            BbLink,
        },
    }
</script>

<style scoped lang="scss">
  .incorrect-data-modal {
    p {
      text-align: justify;
    }
  }
</style>

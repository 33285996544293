export const defaultUiState = () => {
    return {
        editAmount: { visible: false },
        languages: { visible: true },
        progress: {
            page: {
                current: 3,
                max: 3,
            },
            step: { current: 0 },
            visible: false,
        },
    }
}

export const uiStates = {}

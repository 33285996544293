import { products } from '@bigbank/web-loco'
import router from '../../../router/router'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    loanPurposes: null,
}

export const createProductsModule = (client) => {
    const productModule = products.moduleBuilder(client, router)

    return {
        namespaced: true,
        state: {
            ...productModule.state,
            ...state,
        },
        getters: {
            ...productModule.getters,
            ...getters,
        },
        actions: {
            ...productModule.actions,
            ...actions,
        },
        mutations: {
            ...productModule.mutations,
            ...mutations,
        },
    }
}

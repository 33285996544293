import { ui } from '@bigbank/web-loco'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { defaultUiState } from './state'

const uiModule = ui.moduleBuilder({ language: 'bg' })

export default {
    namespaced: true,
    actions: {
        ...uiModule.actions,
        ...actions,
    },
    state: {
        ...uiModule.state,
        ...defaultUiState(),
    },
    getters: {
        ...uiModule.getters,
        ...getters,
    },
    mutations: {
        ...uiModule.mutations,
        ...mutations,
    },
}

<template>
    <bb-offer-thank-you-page :form-props="formData" @submit="redirect">
        <bb-hero slot="header"
                 class="w-100"
                 bleed="all"
                 :title="$t('undecidedPage.title', {value: applicantFirstName})"/>
        <p class="m-b-20 bold small">
            {{ $t('undecidedPage.secondary-title') }}
        </p>
        <p class="m-b-10 smallest">
            {{ $t('undecidedPage.message.line1') }}
        </p>
        <p class="m-b-10 smallest">
            {{ $t('undecidedPage.message.line2') }}
        </p>
    </bb-offer-thank-you-page>
</template>

<script>
    import { BbHero, BbOfferThankYouPage } from '@bigbank/interface-components'

    import { mapGetters } from 'vuex'
    import LinksMixin from '../../../mixins/LinksMixin'

    export default {
        name: 'decision-undecided-page',

        mixins: [
            LinksMixin,
        ],

        computed: {
            ...mapGetters('processStep', ['applicantFirstName']),

            formData() {
                return {
                    uppercaseButtons: true,
                    submitText: this.$t('negativeAnswer.backToSelfService'),
                }
            },
        },

        methods: {
            redirect() {
                window.location = this.getLinks.selfService;
            },
        },

        components: {
            BbOfferThankYouPage,
            BbHero,
        },
    }
</script>

import axios from 'axios'

export const API_V1 = axios.create({ baseURL: '/api/v1/' })
export const API_V2 = axios.create({ baseURL: '/api/v2/' })

/**
 * Interceptors
 */
API_V1.interceptors.response.use((res) => res.data)
API_V2.interceptors.response.use((res) => res.data)

/**
 * End points
 */
export const getUnsignedContractDetails = async ({ externalId }) => {
    return await API_V2.get(`application/${ externalId }/contract/default/unsigned/details`)
}

export const getIdDocumentValidity = async ({ externalId }) => {
    return await API_V1.post(`application-process/${ externalId }/id-document-validity`)
}

export const logProcessError = async (message, args) => {
    return await API_V1.post(`log/process-error`, { message, ...args })
}

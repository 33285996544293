export const DEFAULT_LANGUAGE = 'bg'
export const DEFAULT_WEBSITE_LANGUAGE = 'bg'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_MONTH_YEAR = 'YYYY-MM'

export const IBAN_MAX_LENGTH = 22
export const IBAN_MAX_LENGTH_WITH_SPACES = 27
export const IBAN_PREFIX = 'BG'

export const CONTACT_EMAIL = 'info@bigbank.bg'
export const CONTACT_PHONE = '0700 17 533'

export const UNITS = {
    currency: 'BGN',
    currencySymbol: 'лв.',
    percentage: '%',
}

export const CHANNEL_SELF_SERVICE = 'SELF_SERVICE'
export const CHANNEL_WEBPAGE = 'WEBPAGE'
export const SOURCE_BIGBANK = 'BIGBANK'
export const SERVICE_TYPE_HOMEPAGE = 'HOMEPAGE'

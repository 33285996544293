import Vue from 'vue'
import VueI18n from 'vue-i18n'
import numberFormats from './numberFormat'

import en from '../../translations/en.json'
import bg from '../../translations/bg.json'
import { DEFAULT_LANGUAGE } from '@constants/Global'

export const validationTranslations = {
    en: en.validation,
    bg: bg.validation,
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    numberFormats,
    messages: Object.assign({ en, bg }),
})

export default i18n

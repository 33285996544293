export default {
    methods: {
        translateOption(option, translationPath) {
            return {
                ...option,
                text: this.$t(`${translationPath}.${option.text}`),
            }
        },

        translateOptions(options, translationPath) {
            return options.map((option) => this.translateOption(option, translationPath))
        },
    },
}

<template>
    <bb-offer-thank-you-page :form-props="formData" @submit="onFormSubmit">
        <template slot="header">
            <offer-steps :active-step="currentStep"/>
        </template>
        <div>
            <div class="color-gray-80 f-medium f-small text-center">
                {{ $t('additionalDataPage.title') }}
            </div>
            <bank-account-number-field v-model="data.bankAccountNumber"
                                       :banks="data.bankAccounts"
                                       :bank-account-number="application.applicant.bankAccountNumber"/>
            <monthly-payment-day-field v-model="data.monthlyPaymentDay" class="m-t-20"/>
        </div>
    </bb-offer-thank-you-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BbOfferThankYouPage } from '@bigbank/interface-components'
import isEmpty from 'lodash.isempty'

import BankAccountNumberField from '../components/fields/BankAccountNumberField'
import MonthlyPaymentDayField from '../components/fields/MonthlyPaymentDayField'
import OfferSteps from '../components/offer/OfferSteps'

export default {
    name: 'additional-data-page',

    components: {
        BbOfferThankYouPage,
        BankAccountNumberField,
        MonthlyPaymentDayField,
        OfferSteps,
    },

    data() {
        return {
            currentStep: 3,
            data: {
                bankAccountNumber: null,
                bankAccounts: null,
                monthlyPaymentDay: 5,
            },
        }
    },

    created() {
        this.$set(this.data, 'bankAccounts', this.application.applicant.bankAccounts)
        if (this.application.monthlyPaymentDay) {
            this.$set(this.data, 'monthlyPaymentDay', this.application.monthlyPaymentDay)
        }
    },

    computed: {
        ...mapGetters('processStep', ['isLoading', 'applicantId', 'application']),

        formData() {
            return {
                uppercaseButtons: true,
                submitText: this.$t('additionalDataPage.submitButton'),
                errorText: this.$t('additionalDataPage.errorButton'),
                disabled: this.isLoading || !this.hasBankAccountNumber,
                loading: this.isLoading,
                id: 'additional-data-page',
            }
        },

        hasBankAccountNumber() {
            return !isEmpty(this.data.bankAccountNumber)
        }
    },

    methods: {
        ...mapActions('processStep', ['localUpdateProcessStep']),

        onFormSubmit() {
            const updates = {
                application: {
                    applicationUpdates: {
                        creditConditions: {
                            monthlyPaymentDay: this.data.monthlyPaymentDay,
                        },
                    },
                },
            }

            if (isEmpty(this.application.applicant.bankAccountNumber)) {
                updates.application.personUpdates = {
                    id: this.applicantId,
                    bankAccountNumber: this.data.bankAccountNumber,
                }
            }

            this.localUpdateProcessStep(updates)
        },
    },
}
</script>

<template>
    <section class="m-l-a m-r-a contract-schedule">
        <h1 class="h3 color-blue" :class="{ 'align-center': isMobile }">
            {{ $t('paymentSchedule.title') }}
        </h1>
        <p class="color-gray f-small m-b-20" :class="{ 'align-center': isMobile }">
            {{ $t('paymentSchedule.description') }}
        </p>
        <div>
            <bb-table headUnderline
                      indexColumn
                      striped
                      color="blue"
                      style="max-height: 500px"
                      v-if="payments.length"
                      :columns="columns"
                      :data="payments"
                      :fixed="fixed"
                      class="contract-schedule__table">
                <template slot="footer" v-if="payments.length > 1 && totals.length > 0">
                    <td class="bb-table__row-index"></td>
                    <td colspan="1" :data-total="totals" v-for="(value, index) in totals" :key="index">
                        {{ index > 0 ? $formatAmount(value) : $t('paymentSchedule.table.total') }}
                    </td>
                </template>
            </bb-table>
        </div>
    </section>
</template>

<script>
import get from 'lodash.get'
import { mapGetters } from 'vuex'
import { BbTable } from '@bigbank/interface-components'
import DateHelper from '../../util/DateHelper'

export default {
    name: 'payment-schedule',

    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        BbTable,
    },

    computed: {
        ...mapGetters('contract', ['contractDetails']),

        columns() {
            return [
        { key: 'paymentDate', label: this.$t('paymentSchedule.table.date') },
        { key: 'principal', label: this.$t('paymentSchedule.table.loanAmount') },
        { key: 'interest', label: this.$t('paymentSchedule.table.interest') },
        { key: 'administrationFee', label: this.$t('paymentSchedule.table.accountFee') },
        { key: 'total', label: this.$t('paymentSchedule.table.totalMonthlyPayment') },
            ]
        },

        payments() {
            return get(this.contractDetails, 'paymentSchedule.paymentScheduleLines')
          ? this.contractDetails.paymentSchedule.paymentScheduleLines.reduce((accumulator, yearData) => {
              const currentYearsLines = yearData.lines.filter(line => line.paymentDate).map(line => {
                  const date = DateHelper.format(`${line.paymentDate}.${yearData.year}`, 'DD.MM.YYYY', 'DD-MM-YYYY')

                  return {
                      paymentDate: date,
                      principal: this.$formatAmount(line.principal),
                      interest: this.$formatAmount(line.interest),
                      administrationFee: this.$formatAmount(line.administrationFee),
                      total: this.$formatAmount(line.total),
                  }
              })

              return [
                  ...accumulator,
                  ...currentYearsLines,
              ]
          }, [])
          : []
        },

        totals() {
            if (!get(this.contractDetails, 'paymentSchedule.total')) {
                return []
            }

            return [
                null,
                this.contractDetails.paymentSchedule.total.principal,
                this.contractDetails.paymentSchedule.total.interestAmount,
                this.contractDetails.paymentSchedule.total.administrationFee,
                this.contractDetails.agreementData.paymentAmount,
            ]
        },

        fixed() {
            return {
                head: true,
                firstColumn: true,
                lastRow: true,
                footer: true,
            }
        },
    },
}
</script>

<style lang="scss">
@import '~@bigbank/interface-components/dist/src/scss/variables';
@import '~@bigbank/interface-components/dist/src/scss/vendor/bootstrap/mixins/breakpoints';

.contract-schedule {
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }

  &__table {
    thead,
    tbody {
      tr {
        td,
        td:not(.disable-cell-padding),
        th {
          &:nth-child(2) {
            min-width: 130px;
          }

          &:last-child {
            font-weight: bold;
          }

          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .tfoot {
      font-weight: bold;

      td {
        padding-top: 12px;
        padding-bottom: 12px;

        &:after {
          height: 2px;
        }
      }
    }
  }

  .bb-table__last-row {
    > td {
      bottom: 33px !important;
    }
  }

  .tfoot {
    > td {
      bottom: 0 !important;
    }
  }
}
</style>

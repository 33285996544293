<template>
    <loader v-if="updatingOffer"/>
    <bb-offer-thank-you-page v-else
                             :editButtonLabel="$t('offerPage.tryOutDifferentPlan')"
                             @submit="onFormSubmit"
                             :form-props="formData">
        <template slot="header">
            <offer-steps :activeStep="2"/>
        </template>

        <bb-detailed-radio
            v-model="selectedOffer"
            :options="options"
            value="approvedOffer"
            class="m-b-10"
        />

        <template slot="form-extras">
            <offer-details-list-items v-if="approvedOfferSelected"/>
        </template>

        <template slot="card-back-side">
            <offer-calculator @updateOffer="updateOffer"/>
        </template>
    </bb-offer-thank-you-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OfferSteps from '../components/offer/OfferSteps'
import OfferCalculator from '../components/offer/OfferCalculator'
import { BbOfferThankYouPage, BbDetailedRadio } from '@bigbank/interface-components'
import Loader from '@components/Loader'
import OfferDetailsListItems from '@components/offer/OfferDetailsListItems'

export default {
    name: 'ApprovedOfferWithRefinancingPage',

    components: {
        Loader,
        OfferCalculator,
        BbOfferThankYouPage,
        OfferSteps,
        BbDetailedRadio,
        OfferDetailsListItems,
    },

    data() {
        return {
            selectedOffer: 'approvedOffer',
            updatingOffer: false,
        }
    },

    computed: {
        ...mapGetters('processStep', ['isLoading']),
        ...mapGetters('offer', ['offerDetails']),

        approvedOfferSelected() {
            return this.selectedOffer === 'approvedOffer'
        },

        refinancingSelected() {
            return this.selectedOffer === 'refinancing'
        },

        submitButtonText() {
            return this.approvedOfferSelected
                ? this.$t('approvedOfferWithRefinancingPage.acceptOffer')
                : this.$t('approvedOfferWithRefinancingPage.continue')
        },

        formData() {
            return {
                uppercaseButtons: true,
                submitText: this.submitButtonText,
                disabled: this.isLoading,
                loading: this.isLoading,
                id: 'offer',
            }
        },

        options() {
            return [
                {
                    value: 'approvedOffer',
                    description: this.$t('approvedOfferWithRefinancingPage.options.approvedOffer.description'),
                    leftColumn: {
                        label: this.$formatCurrency(this.offerDetails.offeredAmount),
                        sublabel: this.$t('approvedOfferWithRefinancingPage.options.approvedOffer.period', { value: this.offerDetails.offeredPeriod }),
                        sublabelSmall: this.$t('approvedOfferWithRefinancingPage.options.approvedOffer.interestRate', { value: this.$formatPercentage(this.offerDetails.interestRate) }),
                    },
                    rightColumn: {
                        label: this.$t('approvedOfferWithRefinancingPage.options.approvedOffer.monthlyRepayment'),
                        amount: this.$formatCurrency(this.offerDetails.offeredMonthlyPaymentFull),
                    },
                    special: {
                        enabled: true,
                        label: this.$t('approvedOfferWithRefinancingPage.options.approvedOffer.specialLabel'),
                    },
                },
                {
                    value: 'refinancing',
                    leftColumn: {
                        label: this.$t('approvedOfferWithRefinancingPage.options.refinancing.title'),
                        sublabel: this.$t('approvedOfferWithRefinancingPage.options.refinancing.description'),
                    },
                    extra: {
                        description: this.$t('approvedOfferWithRefinancingPage.options.refinancing.extra'),
                    },
                },
            ]
        },
    },

    methods: {
        ...mapActions('processStep', ['localUpdateProcessStep']),

        onFormSubmit() {
            this.localUpdateProcessStep({
                application: {
                    applicationUpdates: {
                        offerAccepted: true,
                    },
                },
                variables: {
                    isRefinancingChosen: {
                        type: 'Boolean',
                        value: this.refinancingSelected,
                    },
                },
            })
        },

        async updateOffer(amount, period) {
            this.updatingOffer = true
            this.localUpdateProcessStep({
                application: {
                    applicationUpdates: {
                        offerAccepted: false,
                        creditConditions: {
                            loanAmount: amount,
                            loanPeriod: period,
                        },
                    },
                },
                variables: {
                    isRefinancingChosen: {
                        type: 'Boolean',
                        value: false,
                    },
                },
            })
        },
    },
}
</script>

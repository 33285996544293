<template>
    <div>
        <bb-hero
            slot="header"
            class="w-100"
            bleed="all"
            :title="$t('viewSigningCompletedPage.title')"
        />
        <bb-card status="victory" class="text-center">
            <h4 class="f-medium color-navy m-b-15">
                {{ $t('viewSigningCompletedPage.dearCustomer') }}
            </h4>
            <p class="m-b-20 smallest">
                {{ $t('viewSigningCompletedPage.message') }}
            </p>

            <bb-list-group class="f-smallest color-gray m-t-25 clear-side-gutter">
                <bb-list-item
                    :title="$t('viewSigningCompletedPage.amount')"
                >
                    {{ formattedOfferDetails.offeredAmount }}
                </bb-list-item>
                <bb-list-item
                    :title="$t('viewSigningCompletedPage.monthlyPayment')"
                >
                    {{ formattedOfferDetails.offeredMonthlyPaymentFull }}
                </bb-list-item>
                <bb-list-item
                    :title="$t('viewSigningCompletedPage.period')"
                >
                    {{ formattedOfferDetails.offeredPeriod }}
                </bb-list-item>
            </bb-list-group>

            <bb-button class="m-t-20"
                       display="block"
                       :label="$t('viewSigningCompletedPage.continueToSelfService')"
                       @click="redirect()"/>
        </bb-card>
    </div>
</template>

<script>
import LinksMixin from '../../mixins/LinksMixin'
import { BbButton, BbCard, BbHero, BbListGroup, BbListItem } from '@bigbank/interface-components'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'view-signing-completed-page',

    mixins: [
        LinksMixin,
    ],

    computed: {
        ...mapGetters('offer', ['offerDetails']),

        formattedOfferDetails() {
            return {
                offeredAmount: this.$formatCurrency(this.offerDetails.offeredAmount),
                offeredMonthlyPaymentFull: this.$formatCurrency(this.offerDetails.offeredMonthlyPaymentFull),
                offeredPeriod: this.$formatPeriod(this.offerDetails.offeredPeriod, this.$t("viewSigningCompletedPage.loanPeriodUnit")),
            }
        },
    },

    methods: {
        ...mapActions('processStep', ['localUpdateProcessStep']),

        redirect() {
            this.localUpdateProcessStep()
            window.location = this.getLinks.selfService
        },
    },

    components: {
        BbHero,
        BbCard,
        BbButton,
        BbListGroup,
        BbListItem,
    },
}
</script>

export default class RetryHelper {
    constructor(fn, retryCount, timeoutInMs) {
        this.fn = fn
        this.retryCount = retryCount
        this.timeoutInMs = timeoutInMs
    }

    async execute() {
        try {
            return await this.fn()
        } catch (error) {
            if (this.retryCount > 0) {
                this.retryCount--
                
                return await this.delay(async () => await this.execute())
            } else {
                throw error
            }
        }
    }

    async delay(fn) {
        return new Promise((resolve, reject) => {
            this.timeoutHandler = setTimeout(async () => {
                try {
                    resolve(await fn())
                } catch (error) {
                    reject(error)
                }
            }, this.timeoutInMs)
        })
    }

    cancel() {
        clearTimeout(this.timeoutHandler)
        this.timeoutHandler = null
    }
}

<template>
    <bb-modal :visible="true"
              class="consent-modal p-t-50"
              size="x3"
              @close="close()">
        <template slot="heading">{{$t('additionalInformationPage.consents.modal.title')}}</template>
        <p v-html="$t('additionalInformationPage.consents.modal.consent1')"></p>
        <p class="m-t-20" v-html="$t('additionalInformationPage.consents.modal.consent2')"></p>
        <p class="m-t-20" v-html="$t('additionalInformationPage.consents.modal.consent3')"></p>
        <bb-button class="m-t-20"
                   display="block"
                   :label="$t('additionalInformationPage.consents.modal.closeButton')"
                   @click="close()"/>
    </bb-modal>
</template>

<script>
    import { BbButton, BbModal } from '@bigbank/interface-components'


    export default {
        name: 'ConsentModal',

        methods: {
            close() {
                this.$emit('close')
            },
        },

        components: {
            BbModal,
            BbButton,
        },
    }
</script>

<style scoped lang="scss">
  .consent-modal {
    p {
      text-align: justify;
    }
  }
</style>

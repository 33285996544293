import { UNITS } from '@constants/Global'

function _parseValue(value) {
    if (![undefined, null].includes(value)) {
        const floatValue = parseFloat(value.toString().replace(',', '.'))

        if (!isNaN(floatValue)) return floatValue
    }

    return null
}

export default function install(Vue) {
    Vue.prototype.$formatAmount = function (value) {
        const parsedValue = _parseValue(value)

        if (parsedValue === null) return

        return this.$n(parsedValue, 'amount')
    }

    Vue.prototype.$formatCurrency = function (value) {
        const parsedValue = _parseValue(value)

        if (parsedValue === null) return

        return this.$n(parsedValue, 'currency')
    }

    Vue.prototype.$formatCurrencyWithoutLeadingZeroes = function (value) {
        const parsedValue = _parseValue(value)

        if (parsedValue === null) return

        return this.$n(parsedValue, 'currency-no-decimal')
    }

    Vue.prototype.$formatPercentage = function (value) {
        const parsedValue = _parseValue(value)

        if (parsedValue === null) return

        return this.$n(parsedValue, 'percent') + UNITS.percentage
    }

    Vue.prototype.$formatPeriod = function (value, period) {
        const parsedValue = _parseValue(value)

        if (parsedValue === null) return

        return this.$n(parsedValue, 'period') + ` ${period}`
    }

    Vue.prototype.$formatFee = function (fee) {
        if (fee) {
            if (fee.isPercent()) {
                return this.$n(fee.getValue(), 'percent') + UNITS.percentage
            } else if (fee.isFixed()) {
                return this.$n(fee.getValue(), 'currency')
            }
        }

        return ''
    }
}

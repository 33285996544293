import { IBAN_MAX_LENGTH_WITH_SPACES } from '@constants/Global'

export default {
    countCharOccurrence(string, char) {
        return string.split(char).length - 1
    },

    formatIBAN(value) {
        if (!value) {
            return ''
        }

        return value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().substring(0, IBAN_MAX_LENGTH_WITH_SPACES)
    },

    formatLanguage(lang) {
        return lang && lang.toLowerCase()
    },
}

import { calculatePaymentValue } from '@bigbank/interface-components/dist/src/utils/formulas'

export const MAX_PERIOD_COUNT_FOR_LOAN_CALCULATIONS = 180
export const MAX_PERIOD_COUNT_FOR_LOAN_CALCULATIONS_EXCEEDED_MSG =
  'Not able to calculate APRC, this algorithm supports maximum 180 months'
export const CALCULATIONS_NOT_POSSIBLE_MSG = 'Aprc calculation for those inputs is not mathematically possible with the current algorithm'
export const MAX_ITERATION_COUNT = 1000
export const ITERATION_COUNT_EXCEEDED_MSG = 'Took too many iterations. Calculations aborted'

export default {

  /**
   * Copied and adapted from Aprc.php in latvian wordpress project
   *
   * @param amount
   * @param period
   * @param payment
   * @param conclusionFee
   * @returns {*}
   */
    _calculateAprc (amount, period, payment, conclusionFee) {
        if (period > MAX_PERIOD_COUNT_FOR_LOAN_CALCULATIONS) {
            return MAX_PERIOD_COUNT_FOR_LOAN_CALCULATIONS_EXCEEDED_MSG
        }

        let precision = 10E-8

        let aprcCurrent = 0 // Seeding value

        let aprcNext = 0.25

        let iterationCounter = 0

        while (Math.abs(aprcCurrent - aprcNext) > precision) {

            iterationCounter++

            if (iterationCounter >= MAX_ITERATION_COUNT) {
                return ITERATION_COUNT_EXCEEDED_MSG
            }

            aprcCurrent = aprcNext
            let sum = conclusionFee - amount

            let derivativeSum = conclusionFee - amount

            for (let $i = 1; $i <= period; $i++) {
                sum += payment * Math.pow(1 + aprcCurrent, -$i / 12)
                derivativeSum += payment * (-$i / 12) / Math.pow(1 + aprcCurrent, $i / 12 + 1)
            }

      // Get next arpc
            aprcNext = aprcCurrent - sum / derivativeSum
        }

        if (isNaN((aprcNext))) {
            return CALCULATIONS_NOT_POSSIBLE_MSG
        }

        return aprcNext
    },

    getTotalRepaymentAmount ({ offeredMonthlyPaymentFull, offeredPeriod, conclusionFee }) {
        return offeredMonthlyPaymentFull * offeredPeriod + conclusionFee
    },

    getTotalAmountTransferredToAccount ({ offeredAmount, conclusionFee }) {
        return offeredAmount - conclusionFee
    },

    countDecimalPlaces (number) {
        const str = '' + number
        const index = str.indexOf('.')

        return index >= 0 ? str.length - index - 1 : 0
    },

    getFeeAsNumericValue(fee, fullAmount) {
        if (fee) {
            if (fee.isFixed()) {
                return fee.getValue()
            } else if (fee.isPercent() && !isNaN(fullAmount)) {
                return fee.getValue() / 100 * fullAmount
            }
        }

        return null
    },

    getMonthlyPayment(amount, period, interestRate, administrationFeeNumeric) {
        return calculatePaymentValue(
      amount,
      period,
      interestRate,
      administrationFeeNumeric)
    },

  /**
   * Calculates total loan amount
   *
   * @param amount
   * @param period
   * @param interestRate
   * @param administrationFee - as Fee object
   * @param conclusionFee - as Fee object
   * @returns {*}
   */
    getTotalLoanAmount (amount, period, interestRate, administrationFee, conclusionFee) {
        const conclusionFeeNumeric = this.getFeeAsNumericValue(conclusionFee, amount)
        const administractionFeeNumeric = this.getFeeAsNumericValue(administrationFee, amount)

        const monthlyPayment = this.getMonthlyPayment(amount, period, interestRate, administractionFeeNumeric)

        return (monthlyPayment * period + conclusionFeeNumeric).toFixed(2)
    },

  /**
   * Calculates aprc
   *
   * @param amount
   * @param period
   * @param interestRate
   * @param administrationFee - as Fee object
   * @param conclusionFee - as Fee object
   * @returns {*}
   */
    getAPRC (amount, period, interestRate, administrationFee, conclusionFee) {
        const conclusionFeeNumeric = this.getFeeAsNumericValue(conclusionFee, amount)
        const administractionFeeNumeric = this.getFeeAsNumericValue(administrationFee, amount)

        const monthlyPayment = this.getMonthlyPayment(amount, period, interestRate, administractionFeeNumeric)

        const aprc = this._calculateAprc(amount, period, monthlyPayment, conclusionFeeNumeric)

        return isNaN(aprc) ? null : (aprc * 100).toFixed(2)
    },

    calculateAverage (number1, number2) {
        return (number1 + number2) / 2
    },
}

<template>
    <bb-hero class="w-100" bleed="all" :title="title">
        <bb-steps-overview>
            <bb-step-icon
                :done="index < currentStep"
                :icon="step.icon"
                :inactive="index > currentStep"
                :key="step.name"
                :text="step.name"
                v-for="(step, index) in steps"
            />
        </bb-steps-overview>
    </bb-hero>
</template>

<script>
  import {
    BbStepIcon,
    BbStepsOverview,
    BbHero,
  } from '@bigbank/interface-components'
  import '@bigbank/interface-components/dist/svg/client/personal'
  import '@bigbank/interface-components/dist/svg/client/offer'
  import '@bigbank/interface-components/dist/svg/client/sign'

  export default {
      name: 'OfferSteps',

      components: {
          BbStepIcon,
          BbStepsOverview,
          BbHero,
      },

      props: {
          activeStep: {
              type: Number,
              default: 1,
              validator: value => [1, 2, 3, 4].includes(value),
          },
          title: {
              type: String,
              default () {
                  return this.$t('offerPage.title')
              },
          },
      },

      computed: {

          currentStep () {
              return this.activeStep - 1
          },

          steps () {
              let steps = [
                  {
                      name: this.$t('offerSteps.fillInApplication'),
                      icon: 'client-personal',
                  },
                  {
                      name: this.$t('offerSteps.acceptOffer'),
                      icon: 'client-offer',
                  },
                  {
                      name: this.$t('offerSteps.additionalData'),
                      icon: 'client-personal',
                  },
                  {
                      name: this.$t('offerSteps.signContract'),
                      icon: 'client-sign',
                  },
              ]

              return steps
          },
      },

  }
</script>

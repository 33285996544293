<template>
    <div v-if="calculatorDetails">
        <bb-calculator name="offer-calculator"
                       :amount="calculatorDetails.amount"
                       :min-amount="calculatorDetails.amountMin"
                       :max-amount="calculatorDetails.amountMax"
                       :period="calculatorDetails.period"
                       :min-period="calculatorDetails.periodMin"
                       :max-period="calculatorDetails.periodMax"
                       :interest-rate="calculatorDetails.interestRate"
                       :additional-periodic-cost="calculatorDetails.administrationFee"
                       :max-monthly-payment="calculatorDetails.maxMonthlyPayment"
                       :conclusion-fee-fixed="calculatorDetails.conclusionFeeFixed"
                       :conclusion-fee-percent="calculatorDetails.conclusionFeePercent"
                       :daily-aprc-limit="0.00068"
                       :labels="labels"
                       :currency="currency"
                       :locale="$i18n.locale"
                       big-sliders
                       @input="onInput"/>

        <bb-ticket-stub class="m-t-30 nm-l-30 nm-r-30 nm-b-30">
            <bb-button uppercase
                       color="mint"
                       display="ticket"
                       :disabled="!offerChanged || isLoading"
                       :label="$t('offerCalculator.updateOffer')"
                       @click="onSubmit"/>
        </bb-ticket-stub>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BbButton, BbCalculator, BbTicketStub } from '@bigbank/interface-components'
import { UNITS } from '@constants/Global'

export default {
    name: 'OfferCalculator',

    components: {
        BbCalculator,
        BbButton,
        BbTicketStub,
    },

    data() {
        return {
            currentAmount: undefined,
            currentPeriod: undefined,
        }
    },

    computed: {
        ...mapGetters('processStep', ['isLoading']),
        ...mapGetters('offer', ['offerDetails']),

        calculatorDetails() {
            const offer = this.offerDetails

            return {
                amount: offer.offeredAmount,
                amountMin: offer.customerMinAmount,
                amountMax: offer.customerMaxAmount,
                period: offer.offeredPeriod,
                periodMin: offer.customerMinPeriod,
                periodMax: offer.customerMaxPeriod,
                monthlyPayment: offer.offeredMonthlyPayment,
                // Fixes bug in IC calculator component, which can't handle monthly payment calculation if interestRate is 0
                interestRate: offer.interestRate === 0 ? 0.01 : offer.interestRate,
                administrationFee: offer.administrationFee,
                maxMonthlyPayment: offer.customerMaxMonthlyPaymentFull,
                conclusionFeeFixed: offer.conclusionFee,
                conclusionFeePercent: offer.conclusionFeePercent,
            }
        },

        labels() {
            return {
                amount: {
                    title: this.$t('offerCalculator.loanAmount'),
                    unit: UNITS.currencySymbol,
                },
                period: {
                    title: this.$t('offerCalculator.loanPeriod'),
                    unit: this.$t('offerCalculator.loanPeriodUnit'),
                },
                monthlyPayment: {
                    unit: this.$t('offerCalculator.monthlyPaymentUnit'),
                },
                result: {
                    title: this.$t('offerCalculator.monthlyPayment'),
                },
            }
        },

        currency() {
            return UNITS.currency
        },

        offerChanged() {
            return (this.currentAmount !== undefined && this.offerDetails.offeredAmount !== this.currentAmount)
                    || (this.currentPeriod !== undefined && this.offerDetails.offeredPeriod !== this.currentPeriod)
        },
    },

    methods: {
        onInput(inputData) {
            this.currentAmount = inputData.amount;
            this.currentPeriod = inputData.period;
        },

        onSubmit() {
            this.$emit('updateOffer', this.currentAmount, this.currentPeriod)
        },
    },
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import start from '@router/routes/start'
import negativeResult from '@router/routes/negativeResult'
import error from '@router/routes/error'
import offer from '@router/routes/offer'
import decision from '@router/routes/decision'
import contract from '@router/routes/contract'
import additionalData from './routes/additionalData'
import additionalInformation from './routes/additionalInformation'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/',
  // Camunda task routes match Camunda task names
  // Each task must have an unique URL
    routes: [
        ...start,
        ...additionalInformation,
        ...negativeResult,
        ...offer,
        ...decision,
        ...additionalData,
        ...contract,
        ...error,
    ],
    scrollBehavior () {
        return {
            x: 0,
            y: 0,
        }
    },
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'
import { auth, application, LoanOriginationClient, sessionExpiration, signing } from '@bigbank/web-loco'
import calculator from './modules/calculator'
import language from './modules/language'
import { createProductsModule } from './modules/products'
import { createProcessStepModule } from './modules/processStep'
import offer from './modules/offer'
import ui from './modules/ui'
import { createContractModule } from './modules/contract'

Vue.use(Vuex)

// Now possible to set client by some config value
// Switch with dev client for mocking
// const client = LoanOriginationClientDev;
const client = LoanOriginationClient
const store = new Vuex.Store({
    modules: {
        calculator,
        language,
        offer,
        ui,
        application: application.moduleBuilder(client),
        auth: auth.moduleBuilder(client),
        processStep: createProcessStepModule(client),
        products: createProductsModule(client),
        contract: createContractModule(),
        sessionExpiration: sessionExpiration.moduleBuilder(client),
        signing: signing.moduleBuilder(client),
    },
})

client.syncStore(store)

export default store

export const EDUCATION_TYPE_NAMES = {
    BASIC: 'BASIC',
    HIGHER: 'HIGHER',
    SECONDARY: 'SECONDARY',
    SECONDARY_SPECIALISED: 'SECONDARY_SPECIALISED',
}

export const EDUCATION_TYPE_OPTIONS = [
    { value: EDUCATION_TYPE_NAMES.BASIC, text: 'basic' },
    { value: EDUCATION_TYPE_NAMES.SECONDARY, text: 'secondary' },
    { value: EDUCATION_TYPE_NAMES.SECONDARY_SPECIALISED, text: 'secondarySpecialised' },
    { value: EDUCATION_TYPE_NAMES.HIGHER, text: 'higher' },
]

export const LOAN_PURPOSE_NAMES = {
    HOBBY: 'HOBBY',
    PURCHASING_FURNISHINGS: 'PURCHASING_FURNISHINGS',
    PURCHASING_OR_BUILDING_HOME: 'PURCHASING_OR_BUILDING_HOME',
    PURCHASING_OTHER_REAL_ESTATE: 'PURCHASING_OTHER_REAL_ESTATE',
    STARTING_COMMERCIAL_ACTIVITY: 'STARTING_COMMERCIAL_ACTIVITY',
    EXPANDING_COMMERCIAL_ACTIVITY: 'EXPANDING_COMMERCIAL_ACTIVITY',
    ACQUIRING_EDUCATION: 'ACQUIRING_EDUCATION',
    TRAVELING: 'TRAVELING',
    MEDICAL_SERVICES: 'MEDICAL_SERVICES',
    WEDDING: 'WEDDING',
    COMBINING_LOANS: 'COMBINING_LOANS',
    DAILY_SETTLEMENTS: 'DAILY_SETTLEMENTS',
    PURCHASING_MOTOR_VEHICLES: 'PURCHASING_MOTOR_VEHICLES',
    RENOVATING_HOME: 'RENOVATING_HOME',
}

export const LOAN_PURPOSE_OPTIONS = [
    { value: LOAN_PURPOSE_NAMES.HOBBY, text: 'hobby' },
    { value: LOAN_PURPOSE_NAMES.PURCHASING_FURNISHINGS, text: 'purchasingFurnishings' },
    { value: LOAN_PURPOSE_NAMES.PURCHASING_OR_BUILDING_HOME, text: 'purchasingOrBuildingHome' },
    { value: LOAN_PURPOSE_NAMES.PURCHASING_OTHER_REAL_ESTATE, text: 'purchasingOtherRealEstate' },
    { value: LOAN_PURPOSE_NAMES.STARTING_COMMERCIAL_ACTIVITY, text: 'startingCommercialActivity' },
    { value: LOAN_PURPOSE_NAMES.EXPANDING_COMMERCIAL_ACTIVITY, text: 'expandingCommercialActivity' },
    { value: LOAN_PURPOSE_NAMES.ACQUIRING_EDUCATION, text: 'acquiringEducation' },
    { value: LOAN_PURPOSE_NAMES.TRAVELING, text: 'traveling' },
    { value: LOAN_PURPOSE_NAMES.MEDICAL_SERVICES, text: 'medicalServices' },
    { value: LOAN_PURPOSE_NAMES.WEDDING, text: 'wedding' },
    { value: LOAN_PURPOSE_NAMES.COMBINING_LOANS, text: 'combiningLoans' },
    { value: LOAN_PURPOSE_NAMES.DAILY_SETTLEMENTS, text: 'dailySettlements' },
    { value: LOAN_PURPOSE_NAMES.PURCHASING_MOTOR_VEHICLES, text: 'purchasingMotorVehicles' },
    { value: LOAN_PURPOSE_NAMES.RENOVATING_HOME, text: 'renovatingHome' },
]

export const MARITAL_STATUS_NAMES = {
    SINGLE: 'SINGLE',
    COHABITING: 'COHABITING',
    MARRIED: 'MARRIED',
    DIVORCED: 'DIVORCED',
    WIDOW: 'WIDOW',
}

export const MARITAL_STATUS_OPTIONS = [
    { value: MARITAL_STATUS_NAMES.SINGLE, text: 'single' },
    { value: MARITAL_STATUS_NAMES.COHABITING, text: 'cohabiting' },
    { value: MARITAL_STATUS_NAMES.MARRIED, text: 'married' },
    { value: MARITAL_STATUS_NAMES.DIVORCED, text: 'divorced' },
    { value: MARITAL_STATUS_NAMES.WIDOW, text: 'widow' },
]

export const PAYMENT_DAYS_OPTIONS = [
    { value: 5, text: '5' },
    { value: 10, text: '10' },
    { value: 15, text: '15' },
    { value: 20, text: '20' },
]

export const RESIDENCE_TYPE_NAMES = {
    PRIVATE_APARTMENT: 'PRIVATE_APARTMENT',
    PRIVATE_HOUSE: 'PRIVATE_HOUSE',
    RENTAL_PREMISES: 'RENTAL_PREMISES',
    LIVING_WITH_PARENTS: 'LIVING_WITH_PARENTS',
    OTHER: 'OTHER',
}

export const RESIDENCE_TYPE_OPTIONS = [
    { value: RESIDENCE_TYPE_NAMES.PRIVATE_APARTMENT, text: 'privateApartment' },
    { value: RESIDENCE_TYPE_NAMES.PRIVATE_HOUSE, text: 'privateHouse' },
    { value: RESIDENCE_TYPE_NAMES.RENTAL_PREMISES, text: 'rentalApartment' },
    { value: RESIDENCE_TYPE_NAMES.LIVING_WITH_PARENTS, text: 'withParents' },
    { value: RESIDENCE_TYPE_NAMES.OTHER, text: 'other' },
]

export const SOURCE_OF_INFLUENCE_NAMES = {
    ADVERTISEMENT: 'ADVERTISEMENT',
    ARTICLE_MAGAZINE: 'ARTICLE_MAGAZINE',
    BIGBANK_OFFER: 'BIGBANK_OFFER',
    COMPARISON_SITE_OR_BROKER: 'COMPARISON_SITE_OR_BROKER',
    GOOGLE: 'GOOGLE',
    NEWSPAPER: 'NEWSPAPER',
    ONLINE_ADVERTISEMENT: 'ONLINE_ADVERTISEMENT',
    OTHER: 'OTHER',
    RECOMMENDATION: 'RECOMMENDATION',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    TV_ADVERTISEMENT: 'TV_ADVERTISEMENT',
    RADIO: 'RADIO',
}

export const SOURCE_OF_INFLUENCE_OPTIONS = [
  { value: SOURCE_OF_INFLUENCE_NAMES.RADIO, text: 'radio' },
  { value: SOURCE_OF_INFLUENCE_NAMES.ADVERTISEMENT, text: 'advertisement' },
  { value: SOURCE_OF_INFLUENCE_NAMES.ARTICLE_MAGAZINE, text: 'articleMagazine' },
  { value: SOURCE_OF_INFLUENCE_NAMES.BIGBANK_OFFER, text: 'bigbankOffer' },
  { value: SOURCE_OF_INFLUENCE_NAMES.COMPARISON_SITE_OR_BROKER, text: 'comparisonSiteOrBroker' },
  { value: SOURCE_OF_INFLUENCE_NAMES.GOOGLE, text: 'google' },
  { value: SOURCE_OF_INFLUENCE_NAMES.NEWSPAPER, text: 'newspaper' },
  { value: SOURCE_OF_INFLUENCE_NAMES.ONLINE_ADVERTISEMENT, text: 'onlineAdvertisement' },
  { value: SOURCE_OF_INFLUENCE_NAMES.OTHER, text: 'other' },
  { value: SOURCE_OF_INFLUENCE_NAMES.RECOMMENDATION, text: 'recommendation' },
  { value: SOURCE_OF_INFLUENCE_NAMES.SOCIAL_MEDIA, text: 'socialMedia' },
  { value: SOURCE_OF_INFLUENCE_NAMES.TV_ADVERTISEMENT, text: 'tvAdvertisement' },
]

export const getOptionsByValue = (options, values) => options.filter(option => values.includes(option.value))

<template>
    <div class="document-check-modal small text-center">
        <bb-modal :visible="documentCheckStatus === DOCUMENT_CHECK_STATUS_PENDING" @close="cancelDocumentCheck" flavor="user" class="m-t-50" staticOverlay>
            <p class="color-blue">{{ $t('documentCheck.pending.title') }}</p>
            <p class="color-gray-80">{{ $t('documentCheck.pending.description') }}</p>
            <div class="p-y-60" align="center">
                <bb-spinner/>
            </div>
            <bb-button display="link"
                       color="gray"
                       :label="$t('documentCheck.pending.cancel')"
                       @click="cancelDocumentCheck"/>
        </bb-modal>
        <bb-modal :visible="documentCheckStatus === DOCUMENT_CHECK_STATUS_ERROR" @close="cancelDocumentCheck" flavor="user" class="m-t-50" staticOverlay>
            <p class="color-blue">{{ $t('documentCheck.invalid.title') }}</p>
            <bb-icon name="ui-alert-circle-outline" fill="gray-40" size="64" class="m-y-20"/>
            <p class="color-red">{{ $t('documentCheck.invalid.description') }}</p>
            <bb-button display="block"
                       class="m-t-20"
                       color="mint"
                       :label="$t('documentCheck.invalid.close')"
                       @click="cancelDocumentCheck"/>
        </bb-modal>
        <bb-modal :visible="documentCheckStatus === DOCUMENT_CHECK_STATUS_TIMEOUT" @close="cancelDocumentCheck" flavor="user" class="m-t-50" staticOverlay>
            <p class="color-blue">{{ $t('documentCheck.timeout.title') }}</p>
            <p class="color-gray-80">{{ $t('documentCheck.timeout.description') }}</p>
            <bb-button display="block"
                       class="m-t-20"
                       color="mint"
                       :label="$t('documentCheck.timeout.close')"
                       @click="cancelDocumentCheck"/>
        </bb-modal>
    </div>
</template>

<script>
import { BbModal, BbSpinner, BbButton, BbIcon } from '@bigbank/interface-components'
import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline'
import { getIdDocumentValidity } from '@util/ApiService'
import { mapGetters } from 'vuex'

const DOCUMENT_CHECK_STATUS_PENDING = 'PENDING'
const DOCUMENT_CHECK_STATUS_ERROR = 'ERROR'
const DOCUMENT_CHECK_STATUS_TIMEOUT = 'TIMEOUT'

export default {
    name: 'DocumentCheckModal',

    components: {
        BbModal,
        BbSpinner,
        BbButton,
        BbIcon,
    },

    created() {
        this.validityCheckTimeout = setTimeout(this.handleValidityCheckTimeout,
            window.config.SIGNING_DOCUMENT_CHECK_TIMEOUT_SECONDS * 1000)
        this.validityCheckInterval = setInterval(this.checkValidity,
            window.config.SIGNING_DOCUMENT_CHECK_POLLING_INTERVAL_SECONDS * 1000)
        this.checkValidity()
    },

    destroyed() {
        this.stopPolling()
    },

    data() {
        return {
            validityCheckInterval: null,
            validityCheckTimeout: null,
            documentCheckStatus: DOCUMENT_CHECK_STATUS_PENDING,
            DOCUMENT_CHECK_STATUS_PENDING,
            DOCUMENT_CHECK_STATUS_ERROR,
            DOCUMENT_CHECK_STATUS_TIMEOUT
        }
    },

    computed: {
        ...mapGetters('processStep', ['externalId']),
    },

    methods: {
        checkValidity() {
            return getIdDocumentValidity({ externalId: this.externalId }).then(validity => {
                if (validity.status !== undefined && validity.status !== null) {
                    this.stopPolling()
                }

                if (validity.status === 'VALID') {
                    this.$emit('success')
                } else if (validity.status === 'INVALID') {
                    this.documentCheckStatus = DOCUMENT_CHECK_STATUS_ERROR
                }
            })
        },

        stopPolling() {
            clearInterval(this.validityCheckInterval)
            clearTimeout(this.validityCheckTimeout)
        },

        cancelDocumentCheck() {
            this.stopPolling()
            this.$emit('cancel')
        },

        handleValidityCheckTimeout() {
            this.documentCheckStatus = DOCUMENT_CHECK_STATUS_TIMEOUT
            this.stopPolling()
        },
    }
}
</script>

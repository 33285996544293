import { Rules } from 'vee-validate'
import IBAN from 'iban'
import { IBAN_PREFIX } from '@constants/Global'

export const requiredValidator = value => Rules.required(value)
export const customIbanValidator = value => IBAN.isValid(value) && value.substring(0, 2) === IBAN_PREFIX
export const bgPostalCodeValidator = value => value && !!value.match(/^[1-9][0-9]{3}$/)
export const symbolsExtendedValidator = value => value &&
    !!value.match(/^[a-zабвгдежзийклмнопрстуфхцчшщъьюя0123456789\s/*@£$%&(){}\[\]^;:.,´_"'!?~<>|§#¤=+-]+$/i)
export const symbolsValidator = value => value && !!value.match(/^[абвгдежзийклмнопрстуфхцчшщъьюя0123456789\s/.,"'#()-]+$/i)

const rules = {
    requiredSelection: { validate: requiredValidator },
    customIBAN: { validate: customIbanValidator },
    bgPostalCode: { validate: bgPostalCodeValidator },
    symbolsExtended: { validate: symbolsExtendedValidator },
    symbols: { validate: symbolsValidator },
}

export default (Validator) => {
    Object.keys(rules).forEach(rule => {
        Validator.extend(rule, rules[rule])
    })
};

import PageNames from '@constants/PageNames'

const NegativePage = () => import (/* webpackChunkName: "negative-page" */ '@pages/NegativePage')

export default [
    {
        path: '/negative-decision',
        name: PageNames.NEGATIVE_DECISION,
        component: NegativePage,
    },
]

<template>
    <bb-select id="fieldSourceOfInfluence"
               noempty
               v-validate="'requiredSelection|required'"
               name="sourceOfInfluence"
               :label="$t('additionalInformationPage.fields.sourceOfInfluence.title')"
               :helpActive="true"
               :helpText="$t('additionalInformationPage.fields.sourceOfInfluence.tooltip')"
               :options="options"
               v-model="currentValue"
               @change="$emit('input', $event)"/>
</template>

<script>
    import { BbSelect } from '@bigbank/interface-components'
    import TranslateOptionsMixin from '../../../mixins/TranslateOptionsMixin'
    import { SOURCE_OF_INFLUENCE_OPTIONS } from '@constants/Options'

    export default {
        name: 'source-of-influence-field',

        inject: ['$validator'],

        components: { BbSelect },

        mixins: [TranslateOptionsMixin],

        props: {
            value: {
                type: String,
                required: false,
            },
        },

        data() {
            return {
                currentValue: this.value,
            }
        },

        computed: {
            options() {
                return this.translateOptions(SOURCE_OF_INFLUENCE_OPTIONS, 'additionalInformationPage.fields.sourceOfInfluence.options')
            },
        },
    }
</script>

import get from 'lodash.get'
import router from '../../../router/router'
import PageNames from '../../../constants/PageNames'
import { CHANNEL_WEBPAGE, SERVICE_TYPE_HOMEPAGE, SOURCE_BIGBANK } from '@constants/Global'
import trackingData from '../../../util/TrackingData'
import { logProcessError } from '@util/ApiService'

const appStarted = async ({ dispatch, rootState, rootGetters }) => {
    const { externalId, amount, period, productName, segmentName } = router.currentRoute.query

    if (externalId) {
        await dispatch('processStep/continueProcess', externalId, { root: true })
        await dispatch('products/setProductData', {
            amount: get(rootState, 'processStep.application.initialLoanAmount'),
            interestRate: get(rootState, 'processStep.application.initialInterestRate'),
            period: get(rootState, 'processStep.application.initialLoanPeriod'),
        }, { root: true })

    } else if (router.currentRoute.name === PageNames.START_PAGE) {
        const creditConditions = { ...rootGetters['products/creditConditions'] }

        if (!amount || !period || !productName || creditConditions.productName !== productName) {
            await logProcessError('Unable to start application with given request parameters', {
                amount: amount || null,
                period: period || null,
                productName: productName || null,
                referrer: document.referrer,
            })
            await dispatch('processStep/handleError', {}, { root: true })
        } else {
            const activeApplications = await dispatch('application/getActiveApplications', {}, { root: true })

            if (activeApplications.data.applications && activeApplications.data.applications.length > 0) {
                router.replace({ name: PageNames.CHOOSE_CONTINUATION_PAGE, query: router.currentRoute.query })
            } else {
                const data = {
                    application: {
                        type: 'PHYSICAL',
                        creditConditions: {
                            ...creditConditions,
                            segmentName,
                        },
                        source: {
                            source: SOURCE_BIGBANK,
                            channel: CHANNEL_WEBPAGE,
                            createdByServiceType: SERVICE_TYPE_HOMEPAGE,
                            metaData: { tracking: trackingData.getData() },
                        },
                    },
                }

                await dispatch('processStep/createApplication', data, { root: true })
            }
        }
    }
}

const localUpdateProcessStep = async ({ dispatch }, data = {}) => {
    return await dispatch('processStep/updateProcessStep', data, { root: true })
}

export default {
    appStarted,
    localUpdateProcessStep,
}

<template>
    <loader />
</template>

<script>
  import Loader from '../components/Loader'

  export default {
      name: 'start-page',

      components: { Loader },
  }
</script>

<template>
    <div>
        <div class="color-gray-80 f-small f-medium">
            {{ $t('additionalDataPage.fields.monthlyPaymentDay') }}
        </div>
        <bb-radio equalWidth
                  square
                  id="fieldMonthlyPaymentDay"
                  name="monthlyPaymentDay"
                  :options="options"
                  v-model="currentValue"
                  v-validate="'requiredSelection|required'"
                  @change="$emit('input', +$event)"/>
    </div>
</template>

<script>
    import { BbRadio } from '@bigbank/interface-components'
    import { PAYMENT_DAYS_OPTIONS } from '@constants/Options'

    export default {
        name: 'monthly-payment-day-field',

        inject: ['$validator'],

        components: { BbRadio },

        props: {
            value: {
                type: [Number, String],
                default: '',
            },
        },

        data() {
            return {
                options: PAYMENT_DAYS_OPTIONS,
                currentValue: this.value,
            }
        },
    }
</script>

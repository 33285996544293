import PageNames from '@constants/PageNames'
import StartPage from '@pages/StartPage'
import ChooseContinueOptionPage from '@pages/ChooseContinueOptionPage'

export default [
    {
        path: '/start',
        name: PageNames.START_PAGE,
        component: StartPage,
    },
    {
        path: '/continue/:externalId',
        name: PageNames.CONTINUE_PAGE,
        component: StartPage,
        beforeEnter: (to, from, next) => {
            next({
                name: PageNames.START_PAGE,
                query: { externalId: to.params.externalId },
            })
        },
    },
    {
        path: '/choose-continuation',
        name: PageNames.CHOOSE_CONTINUATION_PAGE,
        component: ChooseContinueOptionPage,
    },
]

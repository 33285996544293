import { contract } from '@bigbank/web-loco'
import {
  SET_CONTRACT_DETAILS,
  SET_CONTRACT_LOADING,
} from './types'
import { getUnsignedContractDetails } from '@util/ApiService'

const state = {
    contractDetails: null,
    contractDetailsLoading: true,
}

const getters = {
    contractDetails: state => state.contractDetails,
    contractDetailsLoading: state => state.contractDetailsLoading,
}

const mutations = {
    [SET_CONTRACT_DETAILS]: (state, contractDetails) => {
        state.contractDetails = contractDetails
    },

    [SET_CONTRACT_LOADING]: (state, contractDetailsLoading) => {
        state.contractDetailsLoading = contractDetailsLoading
    },
}

const actions = {
    getUnsignedContractDetails: async ({ commit, rootGetters }) => {
        const externalId = rootGetters['processStep/externalId']

        if (externalId) {
            commit(SET_CONTRACT_LOADING, true)

            const contractDetails = await getUnsignedContractDetails({ externalId })

            commit(SET_CONTRACT_DETAILS, contractDetails)
            commit(SET_CONTRACT_LOADING, false)
        }
    },
}

export const createContractModule = () => {
    const contractModule = contract.moduleBuilder()

    return {
        ...contractModule,
        state,
        mutations,
        actions: {
            ...contractModule.actions,
            ...actions,
        },
        getters: {
            ...contractModule.getters,
            ...getters,
        },
    }
}

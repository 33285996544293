<template>
    <div
        id="app"
        class="app-container d-flex flex-column"
    >
        <app-header :app-loaded="appLoaded" />
        <main class="d-flex flex-column flex-grow-1 bg-gray-10">
            <template v-if="appLoaded">
                <router-view :key="$store.getters['processStep/lastUpdated']" />
                <session-expiration
                    :button-label="$t('views.session-expired.buttonText')"
                    :description="$t('views.session-expired.description')"
                    :title="$t('views.session-expired.title')"
                />
            </template>
            <loader v-else hide-text />
        </main>
        <app-footer />
        <back-navigation-modal
            :description="$t('backButtonModal.description')"
            :positive-button-text="$t('backButtonModal.positiveButtonText')"
            :allow-routing-to-first-page="false"
        />
    </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import {
    BackNavigationModal,
    SessionExpiration,
  } from '@bigbank/web-loco'
  import AppHeader from './views/components/AppHeader'
  import AppFooter from './views/components/AppFooter'
  import Loader from './views/components/Loader'

  export default {
      components: {
          AppFooter,
          AppHeader,
          BackNavigationModal,
          Loader,
          SessionExpiration,
      },

      data () {
          return {
              appLoaded: false,
          }
      },

      async mounted () {
          await this.initProducts()
          await this.appStarted()

          this.appLoaded = true
      },

      methods: {
          ...mapActions('products', ['initProducts']),
          ...mapActions('processStep', ['appStarted']),
      },
  }
</script>

<style lang="scss">
  .app-container {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    cursor: pointer;
  }
</style>

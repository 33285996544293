<template>
    <header class="header">
        <bb-navbar
            sticky
            flavor="public"
            :close-button="isAuthenticatedApplicant"
            :edit-amount="editAmountBlock"
            :languages="languages"
            :progress-bar="progressBar"
            @editAmountClick="onEditAmountClick"
            @languageClick="onLanguageChange"
            @closeClick="logout"
        >
            <bb-logo
                :logo="logo"
                :to="getLinks.homepage"
            />
        </bb-navbar>
        <app-calculator v-if="editAmountVisible && isOpen" />
    </header>
</template>

<script>
  import {
    BbNavbar,
    BbLogo,
  } from '@bigbank/interface-components'
  import logo from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png'
  import AppCalculator from './AppCalculator'
  import {
    mapGetters,
    mapActions,
  } from 'vuex'
  import {
    SET_CALCULATOR_IS_OPEN,
  } from '../../store/modules/calculator'
  import { SET_LANGUAGE } from '../../store/modules/language'
  import LinksMixin from '../../mixins/LinksMixin'

  export default {
      name: 'app-header',

      components: {
          BbNavbar,
          BbLogo,
          AppCalculator,
      },

      props: {
          appLoaded: {
              type: Boolean,
              default: false,
          },
      },

      mixins: [LinksMixin],

      data () {
          return {
              logo,
          }
      },

      computed: {
          ...mapGetters('auth', ['isAuthenticatedApplicant']),
          ...mapGetters('ui', ['editAmountVisible', 'progressBar']),
          ...mapGetters('products', ['getProductData']),
          ...mapGetters('calculator', ['isOpen']),
          ...mapGetters('language', ['availableLanguages']),

          editAmountBlock () {
              const options = {
                  amount: this.getProductData.amount,
                  title: this.$t('loanAmountBar.title'),
              }

              return this.appLoaded && this.editAmountVisible ? options : null
          },

          languages () {
              if (this.appLoaded) {
                  const availableLanguages = this.availableLanguages

                  
                  return availableLanguages.length > 1 ? availableLanguages : null
              } else {
                  return null
              }
          },
      },

      methods: {
          ...mapActions('auth', ['logout']),
          ...mapActions('calculator', [SET_CALCULATOR_IS_OPEN]),
          ...mapActions('language', [SET_LANGUAGE]),

          async onEditAmountClick () {
              this[SET_CALCULATOR_IS_OPEN](true)
          },

          onLanguageChange ({ label }) {
              this[SET_LANGUAGE](label)
          },
      },
  }
</script>

<style lang="scss" scoped>
  .header {
    z-index: 1600;
  }

  .divider {
    height: 40px;
    margin: 0 25px 0 20px;
    border-left: 1px solid $gray-20;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .container-ie {
      position: absolute;
      left: 25%;
      padding-top: 10px;
    }

    .progress-bar-ie {
      left: 0;
    }
  }
</style>

<template>
    <div class="additional-information-page">
        <bb-progress-bar :max="2" :value="1"/>
        <bb-application-page :form-props="formData" @submit="onFormSubmit">
            <bb-public-header slot="header"
                              :title="$t('additionalInformationPage.title')"
                              icon="client-personal"
                              :subtitle="$t('additionalInformationPage.subtitle')"/>
            <div>
                <marital-status-field v-model="data.maritalStatus"/>
                <dependents-field v-model="data.dependents" class="m-t-30"/>
                <residence-type-field v-model="data.residenceType" class="m-t-30"/>
                <education-field v-model="data.education" class="m-t-30"/>
                <loan-purpose-field v-model="data.loanPurpose"
                                    class="m-t-15"
                                    :product-name="creditConditions.productName"
                                    :loan-purpose="creditConditions.loanPurpose"/>
                <bb-input :label="$t('additionalInformationPage.fields.income')"
                          currency
                          name="income"
                          :add-on="currencySymbol"
                          :helpActive="true"
                          :helpText="$t('additionalInformationPage.fields.incomeTooltip')"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="data.income"
                          class="m-t-10"/>
                <bb-input :label="$t('additionalInformationPage.fields.liability')"
                          currency
                          name="liability"
                          :add-on="currencySymbol"
                          :helpActive="true"
                          :helpText="$t('additionalInformationPage.fields.liabilityTooltip')"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="data.liability"
                          class="m-t-10"/>
                <source-of-influence-field v-model="data.sourceOfInfluence" />

                <bb-checkbox :label="$t('additionalInformationPage.fields.contactAddressDiffers')"
                             name="contactAddress"
                             v-model="contractAddressDiffers"
                             class="m-t-20"/>
                <div v-if="contractAddressDiffers">
                    <bb-separator dashed class="m-t-20"/>
                    <bb-input :label="$t('additionalInformationPage.fields.street')"
                              name="street"
                              v-model.trim="data.contactAddress.street"
                              v-validate="'required|min:2|max:200|symbolsExtended'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.houseNumber')"
                              name="houseNumber"
                              v-model.trim="data.contactAddress.houseNumber"
                              v-validate="'min:1|max:10|symbolsExtended'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.entrance')"
                              name="entrance"
                              v-model.trim="data.contactAddress.entrance"
                              v-validate="'min:1|max:35|symbolsExtended'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.floorNumber')"
                              name="floor"
                              v-model.trim="data.contactAddress.floorNumber"
                              v-validate="'min:1|max:10|symbolsExtended'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.flatNumber')"
                              name="flat"
                              v-model.trim="data.contactAddress.flatNumber"
                              v-validate="'min:1|max:10|symbolsExtended'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.postalCode')"
                              name="postalCode"
                              v-model.trim="data.contactAddress.postalCode"
                              v-validate="'bgPostalCode'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.city')"
                              name="city"
                              v-model.trim="data.contactAddress.administrativeAreaLevel3"
                              v-validate="'required|min:2|max:200|symbols'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.province')"
                              name="province"
                              v-model.trim="data.contactAddress.administrativeAreaLevel1"
                              v-validate="'required|min:2|max:200|symbols'"/>
                    <bb-input :label="$t('additionalInformationPage.fields.country')"
                              name="country" disabled
                              :value="$t('additionalInformationPage.fields.countryCode.BG')"/>
                </div>

                <p class="m-t-30 text-center smallest">
                    {{ $t('additionalInformationPage.consents.title') }}
                    <bb-button display="link"
                               color="mint"
                               :label="$t('additionalInformationPage.consents.readMore')"
                               @click="consentModalVisible = true"/>
                </p>

                <p class="m-t-30 text-center smallest">
                    {{ $t('additionalInformationPage.consents.title2') }}
                </p>
            </div>
        </bb-application-page>
        <consent-modal v-if="consentModalVisible"
                       @close="consentModalVisible = false"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
    BbApplicationPage,
    BbButton,
    BbCheckbox,
    BbInput,
    BbProgressBar,
    BbPublicHeader,
    BbSeparator,
} from '@bigbank/interface-components'

import EducationField from '../components/fields/EducationField'
import DependentsField from '../components/fields/DependentsField'
import MaritalStatusField from '../components/fields/MaritalStatusField'
import ResidenceTypeField from '../components/fields/ResidenceTypeField'
import LoanPurposeField from '../components/fields/LoanPurposeField'
import ConsentModal from '../components/ConsentModal'
import { UNITS } from '@constants/Global'
import SourceOfInfluenceField from "@components/fields/SourceOfInfluenceField";

export default {
    name: 'additional-information-page',

    components: {
        SourceOfInfluenceField,
        ConsentModal,
        LoanPurposeField,
        BbApplicationPage,
        BbCheckbox,
        BbProgressBar,
        BbPublicHeader,
        BbInput,
        BbButton,
        BbSeparator,
        EducationField,
        DependentsField,
        MaritalStatusField,
        ResidenceTypeField,
    },

    data() {
        return {
            data: {
                contactAddress: {},
            },
            contractAddressDiffers: null,
            consentModalVisible: false,
        }
    },

    created() {
        this.$set(this.data, 'loanPurpose', this.creditConditions.loanPurpose)
        this.$set(this.data, 'education', this.application.applicant.education)
        this.$set(this.data, 'residenceType', this.application.applicant.residenceType)
        this.$set(this.data, 'dependents', this.application.applicant.dependents)
        this.$set(this.data, 'maritalStatus', this.application.applicant.maritalStatus)
        this.$set(this.data, 'income', this.application.applicant.declaredSalary?.declaredNetMonthlyIncome)
        this.$set(this.data, 'liability', this.application.applicant.declaredLiability?.declaredMonthlyPayment)
        if (this.application.applicant.contactAddress) {
            this.$set(this.data, 'contactAddress', this.application.applicant.contactAddress)
            this.$set(this, 'contractAddressDiffers', true)
        }
        this.$set(this.data, 'sourceOfInfluence', this.application.sourceOfInfluence)
    },

    computed: {
        ...mapGetters('processStep', ['isLoading', 'creditConditions', 'applicantId', 'application']),

        formData() {
            return {
                uppercaseButtons: true,
                submitText: this.$t('additionalInformationPage.submitButton'),
                errorText: this.$t('additionalInformationPage.errorButton'),
                disabled: this.isDisabled(),
                loading: this.isLoading,
                id: 'additional-information-page',
            }
        },

        currencySymbol() {
            return UNITS.currencySymbol
        },
    },

    methods: {
        ...mapActions('processStep', ['localUpdateProcessStep']),

        isDisabled() {
            if (window.config.ADDITIONAL_INFORMATION_PAGE_ENABLED) {
                return this.isLoading
            }

            return true
        },

        onFormSubmit() {
            if (this.isDisabled()) {
                return
            }

            let personUpdates = {
                id: this.applicantId,
                education: this.data.education,
                residenceType: this.data.residenceType,
                declaredData: {
                    dependents: this.data.dependents,
                    maritalStatus: this.data.maritalStatus,
                },
                incomes: [
                    {
                        id: this.application.applicant.declaredSalary?.id || null,
                        type: 'SALARY',
                        declaredNetMonthlyIncome: this.data.income,
                        declaredAmountSource: 'CUSTOMER',
                    },
                ],
                liabilities: [
                    {
                        id: this.application.applicant.declaredLiability?.id || null,
                        type: 'CONSUMER_LOAN',
                        declaredMonthlyPayment: this.data.liability,
                        declaredAmountSource: 'CUSTOMER',
                    },
                ],
                consents: {
                    agreeToPersonalDataProcessing: true,
                    agreeToTerms: true,
                    allowRegistryCheck: true,
                },
            }

            if (this.contractAddressDiffers) {
                personUpdates.communicationMeans = {
                    contactAddress: { ...this.data.contactAddress },
                }
            }

            this.localUpdateProcessStep({
                application: {
                    personUpdates: personUpdates,
                    applicationUpdates: {
                        creditConditions: {
                            loanPurpose: this.data.loanPurpose,
                        },
                        countrySpecificData: {
                            bg: {
                                sourceOfInfluence: this.data.sourceOfInfluence,
                            }
                        }
                    },
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.additional-information-page {
    background-color: white;
    flex-grow: 1;
}
</style>

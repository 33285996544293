<template>
    <div>
        <bb-input center
                  help-active
                  autocomplete="off"
                  class="text-center"
                  :disabled="bankAccountNumberIsPrefilled"
                  id="bankAccountNumberText"
                  name="bankAccountNumber"
                  v-if="bankAccountNumberIsPrefilled || !hasBankAccountNumbers"
                  :value="formattedSelectedBankAccountNumber"
                  v-validate="'required|customIBAN'"
                  data-vv-validate-on="input"
                  :help-text="$t('additionalDataPage.fields.bankAccountNumberTooltip')"
                  :label="$t('additionalDataPage.fields.bankAccountNumber')"
                  @input="onInputChange"/>
        <bb-select center
                   help-active
                   noempty
                   name="bankAccountNumber"
                   id="bankAccountNumber"
                   v-else
                   v-model="selectedBankAccountNumber"
                   v-validate="'requiredSelection|required'"
                   :help-text="$t('additionalDataPage.fields.bankAccountNumberTooltip')"
                   :label="$t('additionalDataPage.fields.bankAccountNumber')"
                   @change="onSelectChange">
            <optgroup v-for="bank in banks"
                      :label="bank.bankName"
                      :key="bank.bankName">
                <option v-for="number in bank.bankAccountNumbers" :value="number" :key="`${bank.bankName}-${number}`">
                    {{ formatIBAN(number) }}
                </option>
            </optgroup>
        </bb-select>

    </div>
</template>

<script>
import { BbInput, BbSelect } from '@bigbank/interface-components'
import StringHelper from '../../../util/StringHelper'
import isEmpty from 'lodash.isempty'

export default {
    name: 'bank-account-number-field',

    inject: ['$validator'],

    components: {
        BbInput,
        BbSelect,
    },

    props: {
        bankAccountNumber: {
            type: String,
            default: '',
        },
        banks: {
            type: Array,
        },
    },

    data() {
        return {
            selectedBankAccountNumber: null,
        }
    },

    computed: {
        bankAccountNumberIsPrefilled() {
            return !isEmpty(this.bankAccountNumber)
        },

        hasBankAccountNumbers() {
            return this.banks && this.banks.length > 0
        },

        formattedSelectedBankAccountNumber() {
            return this.formatIBAN(this.selectedBankAccountNumber)
        },
    },

    created() {
        if (this.bankAccountNumberIsPrefilled) {
            this.selectedBankAccountNumber = this.bankAccountNumber
        }
        this.emit(this.selectedBankAccountNumber)
    },

    methods: {
        emit(value) {
            this.$emit('input', value)
        },

        onSelectChange(value) {
            this.emit(value)
        },

        onInputChange(value) {
            if (!this.bankAccountNumberIsPrefilled) {
                this.selectedBankAccountNumber = StringHelper.formatIBAN(value)
                this.emit(value)
            }
        },

        formatIBAN(value) {
            return StringHelper.formatIBAN(value)
        },
    },
}
</script>

export default {
    creditConditions: (state, getters) => {
        const { amount, period, productName, loanPurpose } = getters.getProductData

        return {
            loanAmount: amount,
            loanPeriod: period,
            loanPurpose: loanPurpose,
            productName,
        }
    },

}

export default {
    CONTINUE_PAGE: 'ContinuePage',
    OFFER_PAGE: 'bg_ViewOffer',
    APPROVED_OFFER_WITH_REFINANCING_PAGE: 'bg_ViewApprovedOfferWithRefinancing',

    START_PAGE: 'StartPage',
    CHOOSE_CONTINUATION_PAGE: 'ChooseContinuationPage',
    NEGATIVE_DECISION: 'bg_ViewNegativeThankYouPage',
    SUBMIT_ADDITIONAL_INFORMATION_PAGE: 'bg_FillAdditionalInformation',
    SUBMIT_ADDITIONAL_DATA_PAGE: 'bg_SubmitAdditionalData',
    VIEW_AND_SIGN_CONTRACT_PAGE: 'bg_ViewAndSignContract',
    VIEW_AND_SIGN_CONTRACT_CONTENT_LOADER_PAGE: 'bg_ContractGenerationLoader',
    VIEW_SIGNING_COMPLETED_PAGE: 'bg_ViewSigningCompletedThankYouPage',

  // Thank you pages
    READ_UNDECIDED_DECISION: 'bg_ViewUndecidedThankYouPage',
    VIEW_APPLICATION_PROCESSING_PAGE: 'bg_ViewApplicationIsBeingProcessedPage',
    VIEW_APPLICATION_PROCESSING_PAGE_APPROVED_REFINANCING: 'bg_ViewApplicationIsBeingProcessedPage_ApprovedRefinancing',

  // Error pages
    ERROR_404_PAGE: 'error404',
    ERROR_500_PAGE: 'error500',
    MAINTENANCE_PAGE: 'maintenance',
    NO_TASK_PAGE: 'noTask',
    NO_PROCESS_PAGE: 'noProcess',
    SESSION_EXPIRED_PAGE: 'sessionExpired',
    UNAUTHORIZED_PAGE: 'unauthorized',
}

import OfferPage from '@pages/OfferPage'
import PageNames from '../../constants/PageNames'
import ApprovedOfferWithRefinancingPage from '@pages/ApprovedOfferWithRefinancingPage'

export default [
    {
        path: '/offer',
        name: PageNames.OFFER_PAGE,
        component: OfferPage,
    },
    {
        path: '/approved-offer-with-refinancing',
        name: PageNames.APPROVED_OFFER_WITH_REFINANCING_PAGE,
        component: ApprovedOfferWithRefinancingPage,
    },
]

<template>
    <div class="relative flex-fill">
        <bb-loading-overlay
            class="text-center"
            color="white"
            :constrained="true"
            :isLoading="true"
            :text="text"
        />
    </div>
</template>

<script>
  import { BbLoadingOverlay } from '@bigbank/interface-components'

  export default {
      name: 'loader',

      props: {
          hideText: {
              type: Boolean,
              default: false,
          },
      },

      components: {
          BbLoadingOverlay,
      },

      computed: {
          text () {
              return !this.hideText && this.$t('loading.info') || ''
          },
      },
  }
</script>

<template>
    <bb-footer condensed
               :contact-links="contactLinks"
               :disclaimer="`© ${ $t('theFooter.copyright') }`"
               :links="links"
               :social-links="socialLinks"/>
</template>

<script>
    import { BbFooter } from '@bigbank/interface-components'
    import { CONTACT_EMAIL, CONTACT_PHONE } from '@constants/Global'
    import LinksMixin from '../../mixins/LinksMixin'
    import '@bigbank/interface-components/dist/svg/social/instagram'
    import '@bigbank/interface-components/dist/svg/social/facebook'
    import '@bigbank/interface-components/dist/svg/ui/email-outline'
    import '@bigbank/interface-components/dist/svg/ui/phone-outline'

    export default {
        name: 'app-footer',

        components: { BbFooter },

        mixins: [LinksMixin],

        computed: {
            contactLinks() {
                return [
                    {
                        label: this.$t('theFooter.cta.giveUsACall'),
                        icon: 'ui-phone-outline',
                        link: {
                            label: CONTACT_PHONE,
                            to: `tel:${CONTACT_PHONE}`,
                        },
                    },
                    {
                        label: this.$t('theFooter.cta.writeUs'),
                        icon: 'ui-email-outline',
                        link: {
                            label: CONTACT_EMAIL,
                            to: `mailto:${CONTACT_EMAIL}`,
                        },
                    },
                ]
            },

            links() {
                return [
                    {
                        label: this.$t('theFooter.links.contact'),
                        to: this.getLinks.contact,
                    },
                    {
                        label: this.$t('theFooter.links.termsAndConditions'),
                        to: this.getLinks.termsAndConditions,
                    },
                    {
                        label: this.$t('theFooter.links.cookiePolicy'),
                        to: this.getLinks.cookiePolicy,
                    },
                    {
                        label: this.$t('theFooter.links.priceList'),
                        to: this.getLinks.priceList,
                    },
                ]
            },

            socialLinks() {
                return [
                    {
                        icon: 'social-instagram',
                        to: this.getLinks.instagram,
                    },
                    {
                        icon: 'social-facebook',
                        to: this.getLinks.facebook,
                    },
                ]
            },
        },
    }
</script>

import DecisionUndecided from '@pages/decision/DecisionUndecided'
import PageNames from '@constants/PageNames'

export default [

  // Undecided
    {
        path: '/decision/undecided',
        name: PageNames.READ_UNDECIDED_DECISION,
        component: DecisionUndecided,
    },
]

import { UNITS } from '@constants/Global'

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: UNITS.currency,
        },
        'currency-no-decimal': {
            style: 'currency',
            currency: UNITS.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        percent: {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        },
        amount: {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        },
        period: {
            style: 'decimal',
            maximumFractionDigits: 0,
        },
    },
    bg: {
        currency: {
            style: 'currency',
            currency: UNITS.currency,
        },
        'currency-no-decimal': {
            style: 'currency',
            currency: UNITS.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        percent: {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        },
        amount: {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        },
        period: {
            style: 'decimal',
            maximumFractionDigits: 0,
        },
    },
}

export default numberFormats

<template>
    <bb-calculator-modal
        name="calculator"
        :visible="isOpen"
        :value="getProductDataForCalculator"
        :listItems="listItems"
        :heading="$t('calculator.title')"
        :submitLabel="$t('calculator.submit')"
        @close="closeCalculator"
        @submit="submit"
        @input="onInput"
    >
        <p slot="disclaimer">{{ $t('calculator.disclaimerText') }}</p>
    </bb-calculator-modal>
</template>

<script>
import { BbCalculatorModal } from '@bigbank/interface-components'
import { mapActions, mapGetters } from 'vuex'
import { SET_CALCULATOR_IS_OPEN } from '@store/modules/calculator'
import MathHelper from '../../util/MathHelper'
import Fee from '../../util/Fee'

export default {
    name: 'AppCalculator',

    components: {
        BbCalculatorModal,
    },

    data() {
        return {
            calculatorData: {
                amount: null,
                period: null,
                interestRate: null,
            },
        }
    },

    computed: {
        ...mapGetters('calculator', ['isOpen']),
        ...mapGetters('products', ['getProductData']),

        getProductDataForCalculator() {
            const productData = this.getProductData

            const conclusionFee = new Fee(productData.contractConclusionFeeRate, productData.contractConclusionFee)
            const administrationFee = new Fee(productData.maintenanceFeeRate, productData.maintenanceFee)
            const administrationFeeNumeric = MathHelper.getFeeAsNumericValue(administrationFee, this.calculatorData.amount)

            const totalLoanAmount = MathHelper.getTotalLoanAmount(this.calculatorData.amount, this.calculatorData.period, this.calculatorData.interestRate, administrationFee, conclusionFee)
            const aprc = MathHelper.getAPRC(this.calculatorData.amount, this.calculatorData.period, this.calculatorData.interestRate, administrationFee, conclusionFee)

            return {
                name: 'BbCalculatorInAppCalculator',
                withInterestInput: true,
                additionalPeriodicCost: administrationFeeNumeric,
                conclusionFee,
                administrationFee,
                totalLoanAmount,
                aprc,
                ...productData,
            }
        },

        listItems() {
            return [
                {
                    title: this.$t('calculator.registrationFee.title'),
                    content: this.$formatFee(this.getProductDataForCalculator.administrationFee),
                    tooltip: this.$t('calculator.registrationFee.tooltip'),
                },
                {
                    title: this.$t('calculator.aprc.title'),
                    content: this.getProductDataForCalculator.aprc ? this.$formatPercentage(this.getProductDataForCalculator.aprc) : '-',
                    tooltip: this.$t('calculator.aprc.tooltip'),
                },
                {
                    title: this.$t('calculator.contractFee.title'),
                    content: this.$formatFee(this.getProductDataForCalculator.conclusionFee),
                    tooltip: this.$t('calculator.contractFee.tooltip'),
                },
                {
                    title: this.$t('calculator.totalLoanAmount.title'),
                    content: this.$formatCurrency(this.getProductDataForCalculator.totalLoanAmount),
                    tooltip: this.$t('calculator.totalLoanAmount.tooltip'),
                },
            ]
        },
    },

    methods: {
        ...mapActions('calculator', [SET_CALCULATOR_IS_OPEN]),
        ...mapActions('products', ['setProductData']),

        submit() {
            this.setProductData(this.calculatorData)
            this.closeCalculator()
        },

        onInput(inputData) {
            this.calculatorData = inputData
        },

        closeCalculator() {
            this[SET_CALCULATOR_IS_OPEN](false)
        },
    },

    created() {
        const productData = this.getProductData

        this.calculatorData.amount = productData.amount
        this.calculatorData.period = productData.period
        this.calculatorData.interestRate = productData.interestRate
    },

}
</script>

<style>
.bb-calculator-modal__submit-button {
  text-transform: uppercase;
}
</style>

import AdditionalInformationPage from '@pages/AdditionalInformationPage'
import PageNames from '../../constants/PageNames'

export default [
    {
        path: '/submit-additional-information',
        name: PageNames.SUBMIT_ADDITIONAL_INFORMATION_PAGE,
        component: AdditionalInformationPage,
    },
]

<template>
    <div class="choose-continue-option-page">
        <bb-application-page
            :form-props="{
                errorText: this.$t('form.errorText'),
                submitText: this.$t('chooseContinueOptionPage.continue'),
                disabled: formDisabled,
                loading: loadingInProgress,
            }"
            class="choose-continue-option"
            @submit="submit"
        >
            <bb-public-header
                slot="header"
                :title="applicantFirstName ? $t('chooseContinueOptionPage.title', {firstName: applicantFirstName}) : $t('chooseContinueOptionPage.titleFallback')"
                :subtitle="$t('chooseContinueOptionPage.subtitle')"
                icon="client-wave"
            />

            <bb-detailed-radio
                v-model="selectedContinueOption"
                v-validate="'required'"
                name="continue-option"
                :options="continueOptions"
            />
        </bb-application-page>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import '@bigbank/interface-components/dist/svg/client'
import { BbApplicationPage, BbDetailedRadio, BbPublicHeader } from '@bigbank/interface-components'
import omitBy from 'lodash.omitby'

const CONTINUE_OPTION = Object.freeze({
    ContinueWithApplication: 'ContinueWithApplication',
    AnnulApplicationAndCreateNew: 'AnnulApplicationAndCreateNew',
})

export default {
    name: 'choose-continue-option-page',

    components: {
        BbApplicationPage,
        BbPublicHeader,
        BbDetailedRadio,
    },

    data() {
        return {
            selectedContinueOption: null,
            activeApplications: null,
            loadingActiveApplications: true,
            annullingActiveApplications: false,
            redirectingToNewApplication: false,
        }
    },

    mounted() {
        this.getActiveApplications(false).then(result => {
            this.activeApplications = result.data.applications
        }).finally(() => {
            this.loadingActiveApplications = false
        })
    },

    computed: {
        ...mapGetters('processStep', ['isLoading']),
        ...mapGetters('products', ['creditConditions']),

        formDisabled () {
            return this.loadingInProgress || this.redirectingToNewApplication
        },

        loadingInProgress() {
            return this.isLoading || this.loadingActiveApplications || this.annullingActiveApplications
        },

        existingApplication() {
            if (this.activeApplications && this.activeApplications.length > 0) {
                return this.activeApplications[0]
            }

            return null
        },

        applicantFirstName() {
            return this.existingApplication && this.existingApplication.applicant.firstName
        },

        currentCreditConditions() {
            const currentQuery = this.$router.currentRoute.query || {}

            return omitBy({
                amount: currentQuery.amount || this.creditConditions.loanAmount,
                period: currentQuery.period || this.creditConditions.loanPeriod,
                productName: currentQuery.productName || this.creditConditions.productName,
                segmentName: currentQuery.segmentName || this.creditConditions.segmentName,
            }, (value) => value === null || value === undefined)
        },

        continueOptions() {
            const options = [
                {
                    value: CONTINUE_OPTION.AnnulApplicationAndCreateNew,
                    description: this.$t('chooseContinueOptionPage.options.newApplication.title'),
                    leftColumn: {
                        label: this.$t('chooseContinueOptionPage.options.newApplication.shortTitle'),
                        sublabel: this.$t('chooseContinueOptionPage.offer.amountAndPeriod',
                            { amount: this.$formatCurrency(this.currentCreditConditions.amount), period: this.currentCreditConditions.period }),
                    },
                },
            ]

            if (this.existingApplication) {
                const creditConditions = this.existingApplication.creditConditions || {}
                const offer = this.existingApplication.offer
                const option = {
                    value: CONTINUE_OPTION.ContinueWithApplication,
                    description: this.$t('chooseContinueOptionPage.options.currentApplication.title'),
                    leftColumn: {
                        label: this.$t('chooseContinueOptionPage.options.currentApplication.shortTitle'),
                        sublabel: this.$t('chooseContinueOptionPage.offer.amountAndPeriod', { amount: this.$formatCurrency(creditConditions.loanAmount, 0), period: creditConditions.loanPeriod }),
                    },
                    special: {
                        enabled: true,
                        label: this.$t('chooseContinueOptionPage.options.currentApplication.specialTitle'),
                    },
                }

                if (offer && this.valueExists(offer.interestRate) && this.valueExists(offer.offeredMonthlyPaymentFull)) {
                    option.leftColumn.sublabelSmall = this.$t('chooseContinueOptionPage.offer.interestRate', { value: offer.interestRate })
                    option.rightColumn = {
                        label: this.$t('chooseContinueOptionPage.offer.monthlyRepayment'),
                        amount: `${this.$formatCurrency(offer.offeredMonthlyPaymentFull) || ''}`,
                    }
                }

                options.unshift(option)
            }

            return options
        },
    },

    methods: {
        ...mapActions('application', ['getActiveApplications', 'annulActiveApplications']),
        ...mapActions('processStep', ['continueProcess']),

        submit() {
            if (this.selectedContinueOption === CONTINUE_OPTION.ContinueWithApplication) {
                this.continueProcess(this.existingApplication.externalId)
            } else {
                this.annullingActiveApplications = true
                this.annulActiveApplications().then(() => {
                    this.redirectingToNewApplication = true;
                    window.location.replace(this.startPageUrl())
                }).finally(() => {
                    this.annullingActiveApplications = false
                })
            }
        },

        startPageUrl() {
            const queryParams = Object.entries(this.currentCreditConditions).map(([key, value]) => {
                return `${key}=${value}`
            }).join('&')

            return "/start?" + queryParams
        },

        valueExists(value) {
            return value !== null && value !== undefined
        }
    },
}
</script>

<style scoped lang="scss">
.choose-continue-option-page {
  background-color: white;
  flex-grow: 1;
}
</style>

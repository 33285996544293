<template>
    <div class="flex-grow-1 text-center bg-gray-10 p-30">
        <bb-error-page v-bind="content"/>
    </div>
</template>

<script>
    import LinksMixin from '../../mixins/LinksMixin'
    import { BbErrorPage } from '@bigbank/interface-components'
    import '@bigbank/interface-components/dist/svg/illustration/error404'
    import '@bigbank/interface-components/dist/svg/illustration/error500'
    import '@bigbank/interface-components/dist/svg/illustration/maintenance'
    import '@bigbank/interface-components/dist/svg/illustration/no-account'
    import '@bigbank/interface-components/dist/svg/illustration/session-expired'

    export default {
        name: 'error-page',

        components: { BbErrorPage },

        props: {
            errorType: {
                type: String,
                required: true,
                validator(value) {
                    return [
                        'error404',
                        'error500',
                        'maintenance',
                        'no-account',
                        'unauthorized',
                        'session-expired',
                    ].indexOf(value) !== -1
                },
            },
        },

        mixins: [LinksMixin],

        computed: {
            content() {
                return {
                    buttonPath: this.getLinks.selfService,
                    buttonText: this.$t(`views.${this.errorType}.buttonText`),
                    description: this.$t(`views.${this.errorType}.description`),
                    icon: `illustration-${this.iconType}`,
                    title: this.$t(`views.${this.errorType}.title`),
                }
            },

            iconType() {
                return this.errorType === 'unauthorized' ? `no-account` : this.errorType
            },
        },
    }
</script>

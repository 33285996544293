<template>
    <loader v-if="updatingOffer" />
    <bb-offer-thank-you-page
        v-else
        :editButtonLabel="$t('offerPage.tryOutDifferentPlan')"
        :form-props="formData"
        :modal-props="{
            size: 'x1',
            centerVertically: true,
            visible: calculatorVisible,
        }"
        use-modal
        @modalOpen="calculatorVisible = true"
        @modalClose="calculatorVisible = false"
        @submit="onFormSubmit"
    >
        <template slot="header">
            <offer-steps :activeStep="currentStep" />
        </template>
        <offer-options
            v-model="selectedOffer"
            class="m-b-10"
        />

        <offer-details-list-items slot="form-extras" />

        <offer-calculator
            slot="modal-content"
            @updateOffer="updateOffer"
        />
    </bb-offer-thank-you-page>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import OfferSteps from '../components/offer/OfferSteps'
import OfferOptions from '../components/offer/OfferOptions'
import OfferDetailsListItems from '../components/offer/OfferDetailsListItems'
import OfferCalculator from '../components/offer/OfferCalculator'
import { BbOfferThankYouPage } from '@bigbank/interface-components'
import Loader from '@components/Loader'

export default {
    name: 'offer-page',

    components: {
        Loader,
        OfferCalculator,
        BbOfferThankYouPage,
        OfferSteps,
        OfferOptions,
        OfferDetailsListItems,
    },

    data() {
        return {
            currentStep: 2,
            selectedOffer: null,
            updatingOffer: false,
            calculatorVisible: false,
        }
    },

    computed: {
        ...mapGetters('processStep', ['isLoading']),

        formData() {
            return {
                uppercaseButtons: true,
                submitText: this.$t('offerButton.acceptOffer'),
                disabled: this.isLoading,
                loading: this.isLoading,
                id: 'offer',
            }
        },
    },

    methods: {
        ...mapActions('processStep', ['localUpdateProcessStep']),

        onFormSubmit() {
            if (!this.calculatorVisible) {
                this.acceptOffer();
            }
        },

        async acceptOffer() {
            this.localUpdateProcessStep({
                application: {
                    applicationUpdates: {
                        offerAccepted: true,
                    },
                },
            })
        },

        async updateOffer(amount, period) {
            this.updatingOffer = true

            this.localUpdateProcessStep({
                application: {
                    applicationUpdates: {
                        offerAccepted: false,
                        creditConditions: {
                            loanAmount: amount,
                            loanPeriod: period,
                        },
                    },
                },
            })
        },
    },
}
</script>

import { LOAN_PURPOSES_SET } from './mutations'
import {
  getLoanPurposes,
  getProductName,
  DEFAULT_PRODUCT,
} from '@constants/ProductSettings'

const initProducts = async ({ dispatch }) => {
    await dispatch('products/getProducts', null, { root: true })
    await dispatch('products/setProductDataFromUrl', null, { root: true })

    return await dispatch(`products/setCurrentProduct`, null, { root: true })
}

const setCurrentProduct = async ({ dispatch, state }, product) => {
    const productName = product || (state.product.productName && getProductName(state.product.productName) || DEFAULT_PRODUCT.productName)

    await dispatch('products/setProduct', productName, { root: true })

    return await dispatch('products/setLoanPurposes', null, { root: true })
}

const setLoanPurposes = ({ commit, state }) => {
    const loanPurposes = getLoanPurposes(state.product.productName)

    commit(LOAN_PURPOSES_SET, loanPurposes)
}

export default {
    initProducts,
    setCurrentProduct,
    setLoanPurposes,
}

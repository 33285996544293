<template>
    <bb-steps-overview>
        <bb-step-icon
            :done="index < currentStep"
            :icon="step.icon"
            :inactive="index > currentStep"
            :key="step.name"
            :text="step.name"
            v-for="(step, index) in steps"
        />
    </bb-steps-overview>
</template>

<script>
  import {
    BbStepIcon,
    BbStepsOverview,
  } from '@bigbank/interface-components'
  import '@bigbank/interface-components/dist/svg/client/contact'
  import '@bigbank/interface-components/dist/svg/client/personal'
  import '@bigbank/interface-components/dist/svg/client/offer'
  import '@bigbank/interface-components/dist/svg/client/sign'

  export default {
      name: 'offer-steps',

      props: {
          activeStep: {
              type: Number,
              default: 1,
              validator: value => [1, 2, 3, 4].includes(value),
          },
      },

      computed: {
          currentStep () {
              return this.activeStep - 1
          },

          steps () {
              return [
                  {
                      name: this.$t('offerSteps.fillInApplication'),
                      icon: 'client-personal',
                  },
                  {
                      name: this.$t('offerSteps.acceptOffer'),
                      icon: 'client-offer',
                  },
                  {
                      name: this.$t('offerSteps.additionalData'),
                      icon: 'client-contact',
                  },
                  {
                      name: this.$t('offerSteps.signContract'),
                      icon: 'client-sign',
                  },
              ]
          },
      },

      components: {
          BbStepIcon,
          BbStepsOverview,
      },
  }
</script>

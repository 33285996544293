export default {
    editAmountVisible: state => state.editAmount.visible,
    languagesVisible: state => state.languages.visible,
    progressBar: state => state.progress.visible
    ? {
        max: state.progress.page.max,
        value: state.progress.page.current,
        color: 'blue',
    }
    : null,
}

import moment from 'moment'
import {
  DATE_FORMAT,
} from '@constants/Global'

export default {

    format (date, formatTo = DATE_FORMAT, formatToShow = DATE_FORMAT) {
        return moment(date, formatTo).format(formatToShow)
    },

}
